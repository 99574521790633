import React, { Component } from 'react'
import ReactTable from "react-table"
import { Link, Redirect } from "react-router-dom"
import _ from "underscore"
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import 'react-table/react-table.css'
import { Badge } from 'reactstrap'

class Grid extends Component {
    baseUrl = cfg.base_api_url + cfg.api_version
    userInfo = auth.getUserInfo()
    config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }


    state = {
        selected: [],
        selectAll: 0,
        data: [],
        pages: null,
        loading: true,
        filtered: true,
        enableDeletar: false,
        enableEditar: false,
        redirectToEdit: false
    }

    toggleRow = (id) => {
        let selecionados = this.state.selected
        let editar = false
        let deletar = false

        if (selecionados.includes(id)) {
            selecionados = selecionados.filter(item => item !== id)
        } else {
            selecionados.push(id)
        }

        if (selecionados.length === 1) {
            editar = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        this.setState({
            enableEditar: editar,
            enableDeletar: deletar,
            selected: selecionados,
            selectAll: 2
        })
    }

    toggleSelectAll = () => {
        let selecionados = []
        let editar = false
        let deletar = false

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                selecionados.push(x.id)
            })
        }

        if (selecionados.length === 1) {
            editar = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        this.setState({
            enableEditar: editar,
            enableDeletar: deletar,
            selected: selecionados,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        })
    }

    getData = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true })

                axios.get(`${this.baseUrl}/${this.userInfo.empresa}/relatorio/?page=${state.page}&pageSize=${state.pageSize}&filter=${encodeURI(JSON.stringify(state.filtered))}`, this.config_me)
                    .then((res) => {
                        console.log(res)
                        const userInfo = auth.getUserInfo()
                        let results = res.data?.results

                        if (userInfo?.tipo === 'cliente') {
                            results = results?.filter(result => result.disponivel_cliente_final)
                        }

                        this.setState({ selected: [], data: results, loading: false, pages: res.data.pages, filtered: true })
                    })
            }
        }
    }, 500)

    deletar = () => {
        let requestArray = []
        this.setState({ loading: true, enableDeletar: false, enableEditar: false })

        this.state.selected.map((value, index) =>
            requestArray.push(axios.delete(`${this.baseUrl}/${this.userInfo.empresa}/relatorio/${value}/`, this.config_me))
        )

        axios.all(requestArray)
            .then(axios.spread((acct, perms) => {
                this.refReactTable.fireFetchData()
            }))
    }

    editar = () => {
        let toGo = `relatorio-customizado/${this.state.selected[0]}`
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from })
    }

    render() {

        const { redirectToEdit } = this.state
        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />
        }

        const columns = [{
            Header: x => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.selectAll === 1}
                        ref={input => {
                            if (input) {
                                input.indeterminate = this.state.selectAll === 2
                            }
                        }}
                        onChange={() => this.toggleSelectAll()}
                    />
                )
            },
            sortable: false,
            width: 55,
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
                return (
                    <div className="hdv-aligncenter-checkboxgrid">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={(this.state.selected.includes(original.id) === true ? true : false)}
                            onChange={() => this.toggleRow(original.id)}
                        />
                    </div>
                )
            }
        }, {
            Header: 'Descrição',
            accessor: 'descricao',
            Cell: ({ original }) => (
                <Link to={`/relatorio-customizado/${original.id}`}>{original.descricao}</Link>
            )
        }, {
            Header: 'Cliente',
            accessor: 'cliente__nome_fantasia'
        }, {
            Header: 'Monitorado',
            accessor: 'monitorado__nome'
        }, {
            Header: 'Equipamento',
            accessor: 'equipamento__imei'
        }, {
            Header: 'Disponível para Cliente final',
            accessor: 'disponivel_cliente_final',
            Cell: ({ original }) => {
                return original.disponivel_cliente_final
                    ? <div style={{ textAlign: "center" }}>
                        <Badge href="#" color="success">Sim</Badge>
                    </div>
                    :
                    <div style={{ textAlign: "center" }}>
                        <Badge href="#" color="secondary">Não</Badge>
                    </div>
            }
        }]

        return (
            <div>
                <h4>Cadastro de Relatórios</h4>
                <div className="hdv-btn-group">
                    <Link to="/relatorio-customizado/novo">
                        <button className="hdv-btn-forms hdv-btn-geen ">Novo</button>
                    </Link>
                    <button onClick={() => { if (this.state.enableEditar === true) { this.editar() } }} className={"hdv-btn-forms hdv-btn-yellow " + (this.state.enableEditar === true ? "" : "hdv-btn-disable")}>Editar / Gerar Relatório</button>
                    <button onClick={() => { if (this.state.enableDeletar === true) { this.deletar() } }} className={"hdv-btn-forms hdv-btn-red " + (this.state.enableDeletar === true ? "" : "hdv-btn-disable")}>Excluir</button>
                </div>

                <ReactTable
                    ref={(refReactTable) => { this.refReactTable = refReactTable }}
                    manual
                    filterable
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    onFetchData={this.getData}
                    columns={columns}
                    defaultPageSize={10}
                    previousText={'Anterior'}
                    nextText={'Próximo'}
                    loadingText={'Carregando...'}
                    noDataText={'Nenhum registro para exibir'}
                    pageText={'Página'}
                    ofText={'de'}
                    rowsText={'linhas'}
                />

            </div>
        )
    }
}

export default Grid
