import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    Image,
} from "@react-pdf/renderer";

const convertDecimalHoursToHourMinuteSecond = (value) => {
    var decimalTimeString = value;
    var decimalTime = parseFloat(decimalTimeString);
    decimalTime = decimalTime * 60 * 60;
    var hours = Math.floor((decimalTime / (60 * 60)));
    decimalTime = decimalTime - (hours * 60 * 60);
    var minutes = Math.floor((decimalTime / 60));
    decimalTime = decimalTime - (minutes * 60);
    var seconds = Math.round(decimalTime);
    if(hours < 10)
    {
        hours = "0" + hours;
    }
    if(minutes < 10)
    {
        minutes = "0" + minutes;
    }
    if(seconds < 10)
    {
        seconds = "0" + seconds;
    }
    return ("" + hours + ":" + minutes + ":" + seconds);
}

export default function PdfDocument(props) {
    console.log(props)
    return (
        <Document>
            <Page size="A4" style={{paddingTop: 10,paddingBottom: 65,paddingHorizontal: 35}}>
                <View style={{display: "flex", flexDirection: "row", padding: 5}}>
                    {(props.data.detalhes_da_empresa.logomarca !== false)
                    ?
                        <Image
                            style={{width: 150}}
                            src={props.data.detalhes_da_empresa.logomarca}
                        />
                    :   <Text>
                            &nbsp;
                        </Text>
                    }
                    <View style={{marginTop: 30, marginLeft: 110, backgroundColor: 'white', width: 250}}>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            {props.data.detalhes_da_empresa.descricao_horario_atendimento}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            {props.data.detalhes_da_empresa.endereco}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            Tel: {props.data.detalhes_da_empresa.telefone}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            E-mail: {props.data.detalhes_da_empresa.email}
                        </Text>
                    </View>
                </View>
                <View>
                    <Text style={{fontSize: 24, textAlign: 'center'}}>
                        Relatório de Entradas Digitais
                    </Text>
                </View>
                <View>
                    {(props.data.charts.vazao_image !== false)
                    ?
                        <Text style={{fontSize: 18, textAlign: 'center', marginBottom: 10, marginTop: 10}}>Volume</Text>
                    :
                        <Text style={{fontSize: 18, textAlign: 'center', marginBottom: 10, marginTop: 10}}>Valores</Text>
                    }

                </View>
                <View style={{display: "flex", flexDirection: "row", padding: 5}}>
                    <View style={{width: 170}}>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'left', color: 'grey'}}>
                            Valor Total: {(props.data.charts.entrada_tipo_selecionada === 2) ? convertDecimalHoursToHourMinuteSecond(parseFloat(props.data.resumo_volume.total).toFixed(3)) : parseFloat(props.data.resumo_volume.total).toFixed(1)}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'left', color: 'grey'}}>
                            Valor das 00H às 05H: {props.data.unidade_selecionada} {(props.data.charts.entrada_tipo_selecionada === 2) ? convertDecimalHoursToHourMinuteSecond(parseFloat(props.data.resumo_volume.horario0005).toFixed(3)) : parseFloat(props.data.resumo_volume.horario0005).toFixed(1)}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'left', color: 'grey'}}>
                            Valor das 06H às 11H: {props.data.unidade_selecionada} {(props.data.charts.entrada_tipo_selecionada === 2) ? convertDecimalHoursToHourMinuteSecond(parseFloat(props.data.resumo_volume.horario0611).toFixed(3)) : parseFloat(props.data.resumo_volume.horario0611).toFixed(1)}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'left', color: 'grey'}}>
                            Valor das 12H às 17H: {props.data.unidade_selecionada} {(props.data.charts.entrada_tipo_selecionada === 2) ? convertDecimalHoursToHourMinuteSecond(parseFloat(props.data.resumo_volume.horario1217).toFixed(3)) : parseFloat(props.data.resumo_volume.horario1217).toFixed(1)}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'left', color: 'grey'}}>
                            Valor das 18H às 23H: {props.data.unidade_selecionada} {(props.data.charts.entrada_tipo_selecionada === 2) ? convertDecimalHoursToHourMinuteSecond(parseFloat(props.data.resumo_volume.horario1823).toFixed(3)) : parseFloat(props.data.resumo_volume.horario1823).toFixed(1)}
                        </Text>
                    </View>
                    <View>
                        {(props.data.charts.volume_image !== false)
                        ?
                            <Image
                                style={{width: 360}}
                                src={props.data.charts.volume_image}
                            />
                        :   <Text>
                                &nbsp;
                            </Text>
                        }
                    </View>
                </View>
                <View>
                    {(props.data.charts.vazao_image !== false)
                    ?
                        <Text style={{fontSize: 18, textAlign: 'center', marginBottom: 10, marginTop: 10}}>Vazão</Text>
                    :   <Text>
                            &nbsp;
                        </Text>
                    }
                </View>
                <View style={{display: "flex", flexDirection: "row", padding: 5}}>
                    {(props.data.charts.vazao_image !== false)
                    ?

                        <View style={{width: 170}}>
                            <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'left', color: 'grey'}}>
                                Vazão Maxíma: {parseFloat(props.data.resumo_vazao.maxima).toFixed(2)} {props.data.unidade_selecionada}
                            </Text>
                            <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'left', color: 'grey'}}>
                                Vazão Média: {parseFloat(props.data.resumo_vazao.media).toFixed(2)} {props.data.unidade_selecionada}
                            </Text>
                            <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'left', color: 'grey'}}>
                                Vazão Minima: {parseFloat(props.data.resumo_vazao.minima).toFixed(2)} {props.data.unidade_selecionada}
                            </Text>
                        </View>

                    :   <Text>
                            &nbsp;
                        </Text>
                    }

                    <View>
                        {(props.data.charts.vazao_image !== false)
                        ?
                            <Image
                                style={{width: 360}}
                                src={props.data.charts.vazao_image}
                            />
                        :   <Text>
                                &nbsp;
                            </Text>
                        }
                    </View>
                </View>
            </Page>
        </Document>
    );
}