import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import Select from 'react-select';
import { CompactPicker } from 'react-color'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import Aviso from '../../components/Aviso'


class Form extends Component {

    state = {
        sistema_cfg: {
            "timezone": "",
            "dominio_personalizado": "",
            "logomarca": "",
            "smtp": "",
            "smtp_porta": "",
            "smtp_usuario": "",
            "smtp_senha": "",
            "link_criar_conta": "",
            "endereco": "",
            "telefone": "",
            "email_contato": "",
            "titulo_sistema": "",
            "descricao_sistema": "",
            "descricao_horario_atendimento": "",
            "facebook": "",
            "linkedin": "",
            "youtube": "",
        },
        cores_cfg: {
            "degrade_top_primeira_cor": "#61abe8",
            "degrade_top_segunda_cor": "#2f577b",
            "back_logomarca": "#37424A",
            "back_menu_lateral": "#37424A",
            "fonte_menu_lateral": "#c7c7c7",
            "fonte_grupo_menu_lateral": "#c7c7c7",
            "login_degrade_primeira_cor_background": "#61abe8",
            "login_degrade_segunda_cor_background": "#2f577b",
            "login_formulario_background": "#ffffff",
            "login_formulario_fonte_cor": "#575757",
            "login_formulario_fonte_link": "#58a9ec",
            "login_informacao_background": "#37424a",
            "login_informacao_fonte_cor": "#aaaaaa",
        },
        formerrors: [],
        redirect: false,
        path: "",
        optionSelected: { "timezone": {} },
        timezone: [],
        logomarca: "",
        configuracao_existe: false,
        aviso: { show: false, message: '' },
        cooldown: false,
    }

    componentDidMount = () => {
        this.getData()
    }

    getData = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-sistema/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-cores/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-timezone/', config_me))

        axios.all(requestArray)
            .then(axios.spread((sistema_cfg, cores_cfg, timezones) => {

                let optionsTimezones = []
                let config = { "timezone": "", "dominio_personalizado": "", "logomarca": "", "smtp": "", "smtp_porta": "", "smtp_usuario": "", "smtp_senha": "" }
                let cores = { "degrade_top_primeira_cor": "#61abe8", "degrade_top_segunda_cor": "#2f577b", "back_logomarca": "#37424A", "back_menu_lateral": "#37424A", "fonte_menu_lateral": "#c7c7c7", "fonte_grupo_menu_lateral": "#c7c7c7" }
                let config_existe = false
                let logomarca = ""
                let selectedOption = { "timezone": {} }

                if (timezones.status === 200) {
                    timezones.data.forEach((a) => {
                        optionsTimezones.push({ "value": a.codigo, "label": "(" + a.horas + ") " + a.codigo })
                    })
                }

                if (sistema_cfg.status === 200) {
                    config_existe = true
                    config = sistema_cfg.data.data
                    selectedOption['timezone'] = sistema_cfg.data.selected
                    logomarca = config['logomarca']
                }

                if (cores_cfg.status === 200) {
                    cores = cores_cfg.data
                }

                this.setState({ "timezone": optionsTimezones, sistema_cfg: config, configuracao_existe: config_existe, logomarca: logomarca, optionSelected: selectedOption, cores_cfg: cores })
            }));
    }

    handleChangeSistema = (e) => {
        var obj = this.state.sistema_cfg
        obj[e.target.id] = e.target.value
        this.setState({ "sistema_cfg": obj })
    }

    handleChangeLogomarca = (e) => {
        var obj = this.state.sistema_cfg
        obj[e.target.id] = e.target.files[0]
        if (e.target.files[0])
            this.setState({ "sistema_cfg": obj, logomarca: URL.createObjectURL(e.target.files[0]) })
    }

    handleChangeColor = (color, element) => {
        var obj = this.state.cores_cfg
        obj[element] = color.hex
        this.setState({ cores_cfg: obj });
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    handleChangeSelect = (selectedOption, field) => {
        var obj = this.state.sistema_cfg
        obj[field] = selectedOption.value
        var optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        this.setState({ "optionSelected": optionObj, "sistema_cfg": obj });
    }

    saveAction = () => {
        this.setState({ cooldown: true })
        let userInfo = auth.getUserInfo();
        let resource = "configuracao-sistema"
        let requestURL = ''
        let verb = ''

        if (this.state.configuracao_existe)
            verb = 'patch'
        else
            verb = 'post'

        requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
        var bodyFormData = new FormData();

        for (var key in this.state.sistema_cfg) {
            bodyFormData.append(key, this.state.sistema_cfg[key]);
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (response.status === 201 || response.status === 200) {

                    let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-cores/';
                    let verb = ''

                    if (this.state.configuracao_existe) {
                        verb = 'patch'
                    } else {
                        verb = 'post'
                    }

                    var bodyFormData = new FormData();

                    for (var key in this.state.cores_cfg) {
                        bodyFormData.append(key, this.state.cores_cfg[key]);
                    }

                    bodyFormData.append('configuracao_id', response.data.id)

                    axios({
                        method: verb,
                        url: requestURL,
                        data: bodyFormData,
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
                    })
                        .then(() => {
                            const aviso = { show: true, message: "Configurações salvas.", args: window.location.reload() }
                            this.setState({ aviso })
                        })

                }
            })
            .catch((error) => console.log(error))
        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }


    render() {
        const timezone = this.state.optionSelected.timezone;

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => window.location.reload()}
                    open={this.state.aviso.show}
                />
                <h4>Configuração</h4>

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Sistema</Tab>
                        <Tab>Informações</Tab>
                        <Tab>Cores do Tema</Tab>
                        <Tab>Configuração SMTP</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label htmlFor="timezone">Timezone do Sistema</label>
                            <Select
                                value={timezone}
                                onChange={(e) => this.handleChangeSelect(e, 'timezone')}
                                options={this.state.timezone}
                            />
                            <label htmlFor="dominio_personalizado">Domínio Personalizado</label>
                            <input type="text" name="dominio_personalizado" id="dominio_personalizado" value={this.state.sistema_cfg.dominio_personalizado} onChange={this.handleChangeSistema} />
                        </form>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label htmlFor="logomarca">Logomarca</label>
                            <input type="file" name="logomarca" id="logomarca" onChange={this.handleChangeLogomarca} />
                            <img className={(this.state.logomarca) ? "hdv-logomarca-preview" : "hdv-noshow-item"} srcSet={this.state.logomarca} alt="Logomarca Preview" />

                            <label htmlFor="link_criar_conta">Link para Criar Nova Conta</label>
                            <input type="text" name="link_criar_conta" id="link_criar_conta" value={this.state.sistema_cfg.link_criar_conta} onChange={this.handleChangeSistema} />

                            <label htmlFor="endereco">Link para Criar Nova Conta</label>
                            <input type="text" name="endereco" id="endereco" value={this.state.sistema_cfg.endereco} onChange={this.handleChangeSistema} />

                            <label htmlFor="telefone">Telefone de Contato</label>
                            <input type="text" name="telefone" id="telefone" value={this.state.sistema_cfg.telefone} onChange={this.handleChangeSistema} />

                            <label htmlFor="email_contato">E-mail de Contato</label>
                            <input type="text" name="email_contato" id="email_contato" value={this.state.sistema_cfg.email_contato} onChange={this.handleChangeSistema} />

                            <label htmlFor="descricao_horario_atendimento">Descrição do Horário de Atendimento</label>
                            <input type="text" name="descricao_horario_atendimento" id="descricao_horario_atendimento" value={this.state.sistema_cfg.descricao_horario_atendimento} onChange={this.handleChangeSistema} />

                            <label htmlFor="titulo_sistema">Título do Sistema</label>
                            <input type="text" name="titulo_sistema" id="titulo_sistema" value={this.state.sistema_cfg.titulo_sistema} onChange={this.handleChangeSistema} />

                            <label htmlFor="descricao_sistema">Descrição do Sistema</label>
                            <input type="text" name="descricao_sistema" id="descricao_sistema" value={this.state.sistema_cfg.descricao_sistema} onChange={this.handleChangeSistema} />

                            <label htmlFor="facebook">Link do Facebook</label>
                            <input type="text" name="facebook" id="facebook" value={this.state.sistema_cfg.facebook} onChange={this.handleChangeSistema} />

                            <label htmlFor="linkedin">Link do LinkedIn</label>
                            <input type="text" name="linkedin" id="linkedin" value={this.state.sistema_cfg.linkedin} onChange={this.handleChangeSistema} />

                            <label htmlFor="youtube">Link do Youtube</label>
                            <input type="text" name="youtube" id="youtube" value={this.state.sistema_cfg.youtube} onChange={this.handleChangeSistema} />

                        </form>
                    </TabPanel>
                    <TabPanel>
                        <form className="hdv-form-default">
                            <h5>Sistema</h5>
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="nome">Background Degradê (Topo) - Primeira Cor</label>
                                    <CompactPicker color={this.state.cores_cfg.degrade_top_primeira_cor} onChange={(color) => this.handleChangeColor(color, 'degrade_top_primeira_cor')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Background Degradê (Topo) - Segunda Cor</label>
                                    <CompactPicker color={this.state.cores_cfg.degrade_top_segunda_cor} onChange={(color) => this.handleChangeColor(color, 'degrade_top_segunda_cor')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Background Logomarca</label>
                                    <CompactPicker color={this.state.cores_cfg.back_logomarca} onChange={(color) => this.handleChangeColor(color, 'back_logomarca')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Background Menu Lateral</label>
                                    <CompactPicker color={this.state.cores_cfg.back_menu_lateral} onChange={(color) => this.handleChangeColor(color, 'back_menu_lateral')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Fonte do Menu Lateral</label>
                                    <CompactPicker color={this.state.cores_cfg.fonte_menu_lateral} onChange={(color) => this.handleChangeColor(color, 'fonte_menu_lateral')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Fonte do Grupo no Menu Lateral</label>
                                    <CompactPicker color={this.state.cores_cfg.fonte_grupo_menu_lateral} onChange={(color) => this.handleChangeColor(color, 'fonte_grupo_menu_lateral')} />
                                </div>
                            </div>
                            <h5 style={{ "marginTop": '15px' }}>Login</h5>
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="nome">Background Degradê - Primeira Cor</label>
                                    <CompactPicker color={this.state.cores_cfg.login_degrade_primeira_cor_background} onChange={(color) => this.handleChangeColor(color, 'login_degrade_primeira_cor_background')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Background Degradê - Segunda Cor</label>
                                    <CompactPicker color={this.state.cores_cfg.login_degrade_segunda_cor_background} onChange={(color) => this.handleChangeColor(color, 'login_degrade_segunda_cor_background')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Background Fromulário</label>
                                    <CompactPicker color={this.state.cores_cfg.login_formulario_background} onChange={(color) => this.handleChangeColor(color, 'login_formulario_background')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Cor da Fonte do Formulário</label>
                                    <CompactPicker color={this.state.cores_cfg.login_formulario_fonte_cor} onChange={(color) => this.handleChangeColor(color, 'login_formulario_fonte_cor')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Cor dos Links do Formulário</label>
                                    <CompactPicker color={this.state.cores_cfg.login_formulario_fonte_link} onChange={(color) => this.handleChangeColor(color, 'login_formulario_fonte_link')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Background Informações</label>
                                    <CompactPicker color={this.state.cores_cfg.login_informacao_background} onChange={(color) => this.handleChangeColor(color, 'login_informacao_background')} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="nome">Cor da Fonte das Informações</label>
                                    <CompactPicker color={this.state.cores_cfg.login_informacao_fonte_cor} onChange={(color) => this.handleChangeColor(color, 'login_informacao_fonte_cor')} />
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-9">
                                    <label htmlFor="smtp">Endereço SMTP</label>
                                    <input type="text" name="smtp" id="smtp" value={this.state.sistema_cfg.smtp} onChange={this.handleChangeSistema} />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="smtp_porta">Porta SMTP</label>
                                    <input type="text" name="smtp_porta" id="smtp_porta" value={this.state.sistema_cfg.smtp_porta} onChange={this.handleChangeSistema} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="smtp_usuario">Usuário</label>
                                    <input type="text" name="smtp_usuario" id="smtp_usuario" value={this.state.sistema_cfg.smtp_usuario} onChange={this.handleChangeSistema} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="smtp_senha">Senha</label>
                                    <input className="hdv-form-system-password-field" type="password" name="smtp_senha" id="smtp_senha" value={this.state.sistema_cfg.smtp_senha} onChange={this.handleChangeSistema} />
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction()} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button>
                    <Link to="/base/operadora">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default Form;