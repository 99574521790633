import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import cfg from '../utils/config';
import auth from '../utils/auth';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { Animated } from "react-animated-css";

class ModalAlerta extends Component {

    state = {
        modal: false,
        informacoes: { monitorado: "", imei: "", itens: [], alerta_descricao: "", tempo: "", status: "", status_key: "", badge: "", nome_fantasia: "", contatos: [], interacoes: [] },
        interacao_content: "",
        showmessage: false,
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    buscar_data = () => {
        this.toggle()
        let userInfo = auth.getUserInfo();
        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/alerta-tratativa/' + this.props.id_alerta + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                console.log(response)
                this.setState({ informacoes: response.data })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    interacao_alerta = (estado_chave, estado, badge) => {

        let interacao_content = this.state.interacao_content

        if (interacao_content.trim() === "") {
            this.setState({ showmessage: true })
            setTimeout(() => {
                this.setState({ showmessage: false })
            }, 4000);
            return
        }

        let userInfo = auth.getUserInfo();
        let dados = new FormData()

        dados.append('conteudo', interacao_content)
        dados.append('estado', estado)
        dados.append('badge', badge)
        dados.append('estado_chave', estado_chave)

        axios({
            method: 'post',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/alerta-tratativa/' + this.props.id_alerta + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() },
            data: dados
        })
            .then((response) => {
                let informacoes = this.state.informacoes
                informacoes.badge = response.data.badge
                informacoes.status = response.data.status
                informacoes.status_key = response.data.status_key
                informacoes.interacoes = response.data.interacoes
                this.setState({ informacoes: informacoes, interacao_content: "", })
            })
            .catch((error) => {
                console.log(error);
            })

    }

    render = () => {

        return (
            <div>
                <span style={{ cursor: "pointer", color: "#007bff" }} onClick={this.buscar_data}>{this.props.label}</span>
                <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Detalhes do Alerta: {this.state.informacoes.alerta_descricao}</ModalHeader>
                    <ModalBody>
                        <Animated style={{ top: '83%' }} className="mensagem-erro" animationIn="fadeInDown" animationOut="fadeOutDown" animateOnMount={false} isVisible={this.state.showmessage}>
                            <div>
                                Por favor descreva o atendimento antes de alterar o seu estado. <br />
                                <i className="fa fa-exclamation mensagem-icone fa-white"></i>
                            </div>
                        </Animated>
                        <Tabs>

                            <TabList>
                                <Tab>Detalhes</Tab>
                                <Tab>Cliente e Contato</Tab>
                                <Tab>Histórico</Tab>
                            </TabList>

                            <TabPanel>
                                <div className="row">
                                    <div className="col-md-12"><h5 style={{ marginTop: '10px' }}>Detalhes do Equipamento</h5></div>
                                    <div className="col-md-6">Monitorado: {this.state.informacoes.monitorado}</div>
                                    <div className="col-md-6">Equipamento: {this.state.informacoes.imei}</div>
                                    {this.state.informacoes.itens.map((item, key) => {
                                        return (
                                            <div key={key} className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">Item: {item.nome}</div>
                                                    <div className="col-md-6">Valor Atual: <b>{item.valor_atual.toFixed(3)}</b></div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="col-md-12">&nbsp;</div>
                                    <div className={(this.state.informacoes.status_key === 'finalizado' || this.state.informacoes.status_key === 'normalizado') ? "hdv-noshow-item" : "col-md-12"}>
                                        Tempo em Aberto: <b style={{ fontSize: '20px', 'color': 'tomato' }}>{this.state.informacoes.tempo}</b>
                                    </div>
                                    <div className="col-md-12">Status Atual: <span className={"badge badge-" + this.state.informacoes.badge}>{this.state.informacoes.status}</span></div>
                                </div>
                                <div className="row">
                                    <div className={(this.state.informacoes.status_key === 'finalizado') ? "hdv-noshow-item" : "col-md-12"}><h5 style={{ marginTop: '10px' }}>Descrição do Atendimento</h5></div>
                                    <div className={(this.state.informacoes.status_key === 'finalizado') ? "hdv-noshow-item" : "col-md-12"}><textarea value={this.state.interacao_content} onChange={(e) => this.setState({ interacao_content: e.target.value })} style={{ width: '100%', height: '150px', borderRadius: '5px' }} ></textarea></div>
                                    <div className="col-md-12">
                                        <button onClick={() => this.interacao_alerta(this.state.informacoes.status_key, this.state.informacoes.status, this.state.informacoes.badge)} style={{ marginRight: '5px' }} className={(this.state.informacoes.status_key === 'finalizado') ? "hdv-noshow-item" : "btn btn-primary"}>Adicionar Interação</button>
                                        <button onClick={() => this.interacao_alerta('pendente', 'Pendente', 'warning')} style={{ marginRight: '5px' }} className={(this.state.informacoes.status_key === 'pendente' || this.state.informacoes.status_key === 'finalizado') ? "hdv-noshow-item" : "btn btn-warning"}>Marcar como Pendente</button>
                                        <button onClick={() => this.interacao_alerta('finalizado', 'Finalizado', 'success')} style={{ marginRight: '5px' }} className={(this.state.informacoes.status_key === 'finalizado') ? "hdv-noshow-item" : "btn btn-success"}>Finalizar</button>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row">
                                    <div className="col-md-12">Cliente: {this.state.informacoes.nome_fantasia}</div>
                                    {this.state.informacoes.contatos.map((item, key) => {
                                        return (
                                            <div key={key} className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">Contato: {item.nome}</div>
                                                    <div className="col-md-3">Telefone: <b>{item.telefone}</b></div>
                                                    <div className="col-md-3">Celular: <b>{item.celular}</b></div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </TabPanel>
                            <TabPanel>
                                {this.state.informacoes.interacoes.map((item, key) => {
                                    return (
                                        <div key={key} className="row" style={{ marginTop: '5px' }}>
                                            <div className="col-md-6">Usuário: {item.usuario}</div>
                                            <div className="col-md-6">Data: {item.data}</div>
                                            <div className="col-md-12">Status: <span className={"badge badge-" + item.badge}>{item.status}</span></div>
                                            <div className="col-md-12">Comentário:</div>
                                            <div className="col-md-12">
                                                <div style={{ backgroundColor: '#f2f2f2', padding: '2px', borderRadius: '2px' }}>
                                                    {item.comentario}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </TabPanel>
                        </Tabs>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.toggle}>Sair</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ModalAlerta