import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    Image
} from "@react-pdf/renderer";

export default function PdfDocument(props) {
    console.log(props)
    return (
        <Document>
            <Page size="A4" style={{paddingTop: 10,paddingBottom: 65,paddingHorizontal: 35}}>
                <View style={{display: "flex", flexDirection: "row", padding: 5}}>
                    {(props.data.detalhes_da_empresa.logomarca)
                    ?
                        <Image
                            style={{width: 150}}
                            src={props.data.detalhes_da_empresa.logomarca}
                        />
                    :   <Text>
                            &nbsp;
                        </Text>
                    }
                    <View style={{marginTop: 30, marginLeft: 110, backgroundColor: 'white', width: 250}}>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            {props.data.detalhes_da_empresa.descricao_horario_atendimento}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            {props.data.detalhes_da_empresa.endereco}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            Tel: {props.data.detalhes_da_empresa.telefone}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            E-mail: {props.data.detalhes_da_empresa.email}
                        </Text>
                    </View>
                </View>

                <View style={{marginTop: 30}}>
                    <Text style={{fontSize: 16, backgroundColor: "#b2bec3", padding: "10px 5px"}}>
                        Relatório de Entradas Analógicas
                    </Text>
                </View>

                <Text  style={{fontSize: "12", padding: "5px 5px", marginTop: 5}}>Cliente: {props.data.parametros.cliente_nome}</Text>
                <Text  style={{fontSize: "12", padding: "5px 5px"}}>Leitura: {props.data.descricao_chart}</Text>
				<Text  style={{fontSize: "12", padding: "5px 5px"}}>Período Selecionado: { props.data.parametros.data.split('-')[0] } à { props.data.parametros.data.split('-')[1] }</Text>

                <Text style={{backgroundColor: "#dfe6e9", padding: "5px 5px", fontSize: "14", marginTop: 5}}>Informações do Gráfico</Text>

                <Text  style={{fontSize: "12", padding: "5px 5px", marginTop: 5}}>Máximo: {parseFloat(props.data.resumo.maximo).toFixed(1)} {props.data.unidade_selecionada}</Text>
                <Text  style={{fontSize: "12", padding: "5px 5px"}}>Mínimo: {parseFloat(props.data.resumo.minimo).toFixed(1)} {props.data.unidade_selecionada}</Text>

                <View style={{marginTop: 30}}>
                    {(props.data.charts.chart_image)
                    ?
                        <Image
                            src={props.data.charts.chart_image}
                        />
                    :   <Text>
                            &nbsp;
                        </Text>
                    }
                </View>
                {(props.data.parametros.data.split('-')[0] !== props.data.parametros.data.split('-')[1])
                ?
                    <View style={{display: "flex", flexDirection: "row", padding: 5, textAlign: 'center'}}>
                        <Text style={{fontSize: "12", padding: "5px 0px", color: 'green'}}> • Máximo </Text>
                        <Text style={{fontSize: "12", padding: "5px 0px", color: 'blue'}}> • Mínimo  </Text>
                    </View>
                :   
                    <Text>
                        &nbsp;
                    </Text>
                }

            </Page>
        </Document>
    );
}