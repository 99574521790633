import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";

class Form extends Component {

    state = {
        detalhes: {"fabricante": "", "status": ""},
        id_editando_contato: false,
        id_editando_endereco: false,
        formerrors: [],
    }

    handleChange = (e) => {
        var obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        this.setState({"detalhes": obj})
    }

    render() {
        console.log(this.state.detalhes)
        return (
            <div>
                <h4>Novo Fabricante</h4>

                <div className="hdv-btn-group">
                    <button className="hdv-btn-forms hdv-btn-geen ">Salvar</button>
                    <button className="hdv-btn-forms hdv-btn-geenlight ">Salvar e Cadastrar um Novo</button>
                    <Link to="/base/fabricante">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes do Fabricante</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label htmlFor="fabricante">Nome do Fabricante</label>
                            <input type="text" name="fabricante" id="fabricante" value={this.state.detalhes.fabricante} onChange={this.handleChange} />
                            <label htmlFor="status">Status</label>
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'ativo'} value="ativo" onChange={this.handleChange} /> Ativo
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'bloqueado'} value="bloqueado" onChange={this.handleChange} /> Bloqueado
                            <input type="radio" name="status" id="status" checked={this.state.detalhes.status === 'cancelado'} value="cancelado" onChange={this.handleChange} /> Cancelado
                        </form>
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

export default Form;