import React from 'react'
import { Button } from 'reactstrap'


export default class Aviso extends React.Component {

    render() {
        return (
            <div className="aviso-backdrop" hidden={!this.props.open}>
                <div className="aviso-feedback">
                    <h5><i className="fa fa-exclamation fa-20px"></i> Aviso</h5>
                    {this.props.mensagem}
                    <br />
                    <hr />
                    <div className="aviso-buttons">

                        {this.props.continue ? <>
                            <Button color="secondary" onClick={this.props.continue}>Continuar</Button>&nbsp;
                            </>
                            :
                            null
                        }
                        <Button color="success" onClick={this.props.action}>OK</Button>&nbsp;
                    </div>
                </div>
            </div>
        )
    }
}