import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import Select from 'react-select';
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import Aviso from '../../components/Aviso'

class Form extends Component {

    state = {
        detalhes: { "nome": "", "cliente_id": "", "descricao": "" },
        formerrors: [],
        redirect: false,
        path: "",
        clientes: [],
        selectedOption: null,
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
    }

    componentWillMount = () => {
        let resource = "cliente"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                let optionsCliente = []
                if (response.data.length > 0) {
                    response.data.forEach((c) => {
                        optionsCliente.push({ "value": c.id, "label": c.nome_fantasia })
                    })
                }
                this.setState({ clientes: optionsCliente });
            })
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let resource = "aplicacao"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                delete response.data.id
                this.setState({ detalhes: response.data, selectedOption: { value: response.data.cliente_id, label: response.data.cliente__nome_fantasia } });
            })
    }

    handleChange = (e) => {
        var obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        document.querySelector(`#validacao-${e.target.name}`).innerHTML = ''
        this.setState({ "detalhes": obj })
    }

    handleChangeSelect = (selectedOption, field) => {
        console.log(selectedOption, field)
        var obj = this.state.detalhes
        obj[field] = selectedOption.value
        document.querySelector(`#validacao-cliente`).innerHTML = ''
        this.setState({ selectedOption, "detalhes": obj });
    }

    saveAction = (novo_cadastro) => {
        this.setState({ cooldown: true })

        let userInfo = auth.getUserInfo();
        let resource = "aplicacao"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }
        var bodyFormData = new FormData();

        for (var key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key]);
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    if (this.props.match.params.id) {
                        const aviso = { show: true, message: `Aplicação ${verb === 'patch' ? 'editada' : 'criada'} com sucesso.`, args: { redirect: true, path: "/aplicacao/novo" } }
                        this.setState({ aviso })
                    } else {
                        window.location.reload()
                    }
                } else {
                    const aviso = { show: true, message: `Aplicação ${verb === 'patch' ? 'editada' : 'criada'} com sucesso.`, args: { redirect: true, path: "/aplicacao" } }
                    this.setState({ aviso })
                }
            })
            .catch((error) => {
                console.log(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    generateOption = (source, value, desc) => {
        let options = source.map((obj, idx) =>
            <option key={idx} value={obj[value]}>{obj[desc]}</option>
        );
        return options;
    }

    render() {
        const { selectedOption } = this.state;

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>

                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />
                {this.props.match.params.id ? <h4>Editar Aplicação</h4> : <h4>Nova Aplicação</h4>}

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes da Aplicação</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label className="required" htmlFor="cliente_id">Cliente</label>
                            <Select
                                name={"cliente_id"}
                                id={"cliente_id"}
                                value={selectedOption}
                                onChange={(e) => this.handleChangeSelect(e, 'cliente_id')}
                                options={this.state.clientes}
                            />
                            <span id="validacao-cliente"></span>
                            <label className="required" htmlFor="nome">Nome da Aplicação</label>
                            <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={this.handleChange} />
                            <span id="validacao-nome"></span>
                            <label htmlFor="descricao">Descrição</label>
                            <input type="text" name="descricao" id="descricao" value={this.state.detalhes.descricao} onChange={this.handleChange} />
                            <span id="validacao-descricao"></span>
                        </form>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button>
                    <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geenlight" disabled={this.state.cooldown}>Salvar e Cadastrar um Novo</button>
                    <Link to="/aplicacao">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default Form;