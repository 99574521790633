import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import Grid from './grid'
import Form from './form'
import PrivateRoute from '../PrivateRoute'

class RelatorioConsumo extends Component {
   render() {
      return (
         <div className="hdv-render-div" style={{ marginBottom: '65px' }}>
            <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
               <Switch>
                  <PrivateRoute exact path='/relatorio-consumo' component={Grid} />
                  <PrivateRoute path='/relatorio-consumo/novo' component={Form} />
                  <PrivateRoute path='/relatorio-consumo/:id' component={Form} />
               </Switch>
            </div>
         </div>
      )
   }
}

export default RelatorioConsumo
