import React, { Component } from 'react'
import ReactTable from "react-table"
import { Redirect } from "react-router-dom"
import _ from "underscore"
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import 'react-table/react-table.css'

class Grid extends Component {
    state = {
        selected: [],
        selectAll: 0,
        data: [],
        pages: null,
        loading: true,
        filtered: true,
        enableLancamento: false,
        redirectToLancamento: false,
    }

    toggleRow = (id) => {
        let selecionados = this.state.selected
        let lancamento = false

        if (selecionados.includes(id))
            selecionados = selecionados.filter(item => item !== id)
        else
            selecionados.push(id)

        if (selecionados.length === 1) {
            lancamento = true
        }

        this.setState({
            enableLancamento: lancamento,
            selected: selecionados,
            selectAll: 2
        })
    }

    toggleSelectAll = () => {
        let selecionados = []
        let lancamento = false

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                selecionados.push(x.id)
            })
        }

        if (selecionados.length === 1)
            lancamento = true


        this.setState({
            enableLancamento: lancamento,
            selected: selecionados,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        })
    }

    getData = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true })
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
                let userInfo = auth.getUserInfo()

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(state.filtered)), config_me)
                    .then((response) => {
                        this.setState({ selected: [], data: response.data.results, loading: false, pages: response.data.pages, filtered: true })
                    })
            }
        }
    }, 500)

    realizarLancamento = () => {
        let toGo, from
        let selecionado = this.state.data.filter(d => d.id === this.state.selected[0])[0]

        toGo = `virtual/${selecionado.imei}/lancamento`
        from = { pathname: toGo }

        this.setState({ redirectToLancamento: from, })
    }

    render() {
        const { redirectToLancamento, enableLancamento, selectAll, selected } = this.state

        if (redirectToLancamento)
            return <Redirect to={redirectToLancamento} />

        const columns = [{
            Header: x => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectAll === 1}
                        ref={input => {
                            if (input) {
                                input.indeterminate = selectAll === 2
                            }
                        }}
                        onChange={() => this.toggleSelectAll()}
                    />
                )
            },
            sortable: false,
            width: 55,
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
                return (
                    <div className="hdv-aligncenter-checkboxgrid">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={(selected.includes(original.id) === true ? true : false)}
                            onChange={() => this.toggleRow(original.id)}
                        />
                    </div>
                )
            }
        }, {
            Header: 'Imei',
            accessor: 'imei'
        }, {
            Header: 'Fabricante',
            accessor: 'fabricante__nome'
        }, {
            Header: 'Modelo',
            accessor: 'modelo__modelo',
            filterable: false,
        }, {
            Header: 'Status',
            accessor: 'status',
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}>
                    <option value="">Mostrar Todos</option>
                    <option value="ativo">Ativo</option>
                    <option value="bloqueado">Bloqueado</option>
                </select>
        }]

        return (
            <>
                <div>
                    <h4>Cadastro de Equipamentos Virtuais</h4>
                    <div className="hdv-btn-group" >
                        <button
                            onClick={this.realizarLancamento}
                            className="hdv-btn-forms hdv-btn-blue"
                            disabled={!enableLancamento}>
                            Realizar Lançamento
                        </button>
                    </div>

                    <ReactTable
                        ref={(refReactTable) => { this.refReactTable = refReactTable }}
                        manual
                        filterable
                        data={this.state.data}
                        pages={this.state.pages}
                        loading={this.state.loading}
                        onFetchData={this.getData}
                        columns={columns}
                        defaultPageSize={10}
                        previousText={'Anterior'}
                        defaultFiltered={[{ id: "modelo_id", value: "8", type: "int" }]}
                        nextText={'Próximo'}
                        loadingText={'Carregando...'}
                        noDataText={'Nenhum registro para exibir'}
                        pageText={'Página'}
                        ofText={'de'}
                        rowsText={'linhas'}
                    />
                </div>
            </>
        )
    }
}

export default Grid 
