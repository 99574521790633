import React, { Component } from 'react';
import { Switch } from 'react-router-dom'
import Grid from './grid'
import Form from './form'
import PrivateRoute from '../../containers/PrivateRoute'

class Aplicacao extends Component {
	render() {
		return (
			<div className="hdv-render-div">
				<div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">

                    <Switch>
                        <PrivateRoute exact path='/aplicacao' component={Grid}/>
                        <PrivateRoute path='/aplicacao/novo' component={Form}/>
                        <PrivateRoute path='/aplicacao/:id/editar' component={Form}/>
                    </Switch>

				</div>
			</div>
		);
	}
}

export default Aplicacao;
