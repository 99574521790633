import React, { Component, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PdfDocument from "../../utils/PdfEletrico";
import HeaderReportDefault from '../../components/HeaderReportDefault'
import ReactLoading from 'react-loading';
import moment from 'moment'

import axios from 'axios'
import CsvDownloader from 'react-csv-downloader';
import auth from '../../utils/auth';
import cfg from '../../utils/config';

import { PDFDownloadLink } from '@react-pdf/renderer';

import {
	BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line,
} from 'recharts';


const data = [{ name: 'Group A', value: 75 }, { name: 'Group B', value: 25 }];
const data2 = [{ name: 'Group A', value: 56.25 }, { name: 'Group B', value: 47.75 }];
const COLORS = ['#e74c3c', 'silver'];
const COLORS2 = ['#27ae60', '#ffffff00'];

const PNG = require('save-svg-as-png');


class CustomizedLabel extends PureComponent {
	render() {
		const { x, y, value } = this.props

		return <text x={x} y={y} dy={13} dx={13} fill={"#fff"} fontSize={10} textAnchor="middle">{Number(value).toFixed(2)}</text>
	}
}

class RelatorioEnergia extends Component {

	state = {
		detalhesDefault: { "data": "", "cliente": "", "monitorado": "", "item": null },
		detalhesExtra: {},
		hidrometrosOption: [],
		configuracaoSelecionada: null,
		reporte: [],
		tensao: [],
		data: [],
		resumo: false,
		tickFormatter: (tick) => moment(tick * 1000).format('HH:mm'),
		ticks: [
			moment("2019-04-02 00:00:00").format('X'),
			moment("2019-04-02 01:00:00").format('X'),
			moment("2019-04-02 02:00:00").format('X'),
			moment("2019-04-02 03:00:00").format('X'),
			moment("2019-04-02 04:00:00").format('X'),
			moment("2019-04-02 05:00:00").format('X'),
			moment("2019-04-02 06:00:00").format('X'),
			moment("2019-04-02 07:00:00").format('X'),
			moment("2019-04-02 08:00:00").format('X'),
			moment("2019-04-02 09:00:00").format('X'),
			moment("2019-04-02 10:00:00").format('X'),
			moment("2019-04-02 11:00:00").format('X'),
			moment("2019-04-02 12:00:00").format('X'),
			moment("2019-04-02 13:00:00").format('X'),
			moment("2019-04-02 14:00:00").format('X'),
			moment("2019-04-02 15:00:00").format('X'),
			moment("2019-04-02 16:00:00").format('X'),
			moment("2019-04-02 17:00:00").format('X'),
			moment("2019-04-02 18:00:00").format('X'),
			moment("2019-04-02 19:00:00").format('X'),
			moment("2019-04-02 20:00:00").format('X'),
			moment("2019-04-02 21:00:00").format('X'),
			moment("2019-04-02 22:00:00").format('X'),
			moment("2019-04-02 23:00:00").format('X'),
		],
		tickDomain: { start: moment("2019-04-02 00:00:00").format('X'), end: moment("2019-04-02 23:00:00").format('X') },
		labelFormatter: (label) => moment.unix(label).format('HH:mm:ss'),
		unidade_selecionada: "",
		validacao_formulario: { data: true, cliente: true, monitorado: true, item: true, canal: true },
		carregando_dados: false,
		pr_tri: { refAreaLeft: false, refAreaRight: false },
		relatorio_gerado: false,
		gerando_pdf: false,
		pdfData: { vazao_image: false, volume_image: false },
		detalhes_da_empresa: { "logomarca": false, "endereco": "", "telefone": "", "email": "", "descricao_horario_atendimento": "" },
		abrir_pdf: false,
		dataEletrico: [],
		create_pdf_link: false,
		dados: [{}],
		campos_dados_eletricos: [
			{
				"field": "energia_ativa",
				"label": "Energia Ativa",
				"type": "bar",
				"chart_line": [{ label: "Energia Ativa", color: "green", key: "Ativa" }, { label: "Energia Reativa", color: "black", key: "Reativa" }],
				"chart_reference_line": [],
				"block": 2,
				"tooltip_formatter": (value) => parseFloat(value).toFixed(2).toString() + " " + this.state.unidade_selecionada
			},
			{
				"field": "tensao",
				"label": "Tensão",
				"type": "line",
				"chart_line": [{ label: "Fase RS", color: "green", key: "fase_rs" }, { label: "Fase RT", color: "blue", key: "fase_rt" }, { label: "Fase ST", color: "red", key: "fase_st" }],
				"chart_reference_line": [{ value: 150, label: "Máximo", color: "g" }],
				"block": 1,
				"tooltip_formatter": (value) => parseFloat(value).toFixed(2)
			},
			{
				"field": "corrente",
				"label": "Corrente",
				"type": "line",
				"chart_line": [{ label: "Fase RS", color: "green", key: "fase_rs" }, { label: "Fase RT", color: "blue", key: "fase_rt" }, { label: "Fase ST", color: "red", key: "fase_st" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => parseFloat(value).toFixed(2)
			},
			{
				"field": "corrente_trifasica",
				"label": "Corrente Trifásica",
				"type": "line",
				"chart_line": [{ label: "Corrente Trifásica", color: "blue", key: "" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => parseFloat(value).toFixed(2)
			},
			{
				"field": "tensao_trifasica",
				"label": "Tensão Trifásica",
				"type": "line",
				"chart_line": [{ label: "Tensão Trifásica", color: "blue", key: "" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => parseFloat(value).toFixed(2)
			},
			{
				"field": "potencia_ativa_trifasica",
				"label": "Potência Ativa Trifásica",
				"type": "line",
				"chart_line": [{ label: "Potência Ativa Trifásica", color: "blue", key: "" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => parseFloat(value).toFixed(2)
			},
			{
				"field": "potencia_reativa_trifasica",
				"label": "Potência Reativa Trifásica",
				"type": "line",
				"chart_line": [{ label: "Potência Reativa Trifásica", color: "blue", key: "" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => parseFloat(value).toFixed(2)
			},
			{
				"field": "demanda_ativa",
				"label": "Demanda Ativa",
				"type": "line",
				"chart_line": [{ label: "Demanda Ativa", color: "blue", key: "" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => parseFloat(value).toFixed(2)
			},
			{
				"field": "fator_potencia_trifasica",
				"label": "Fator de Potência Trifásica",
				"type": "line",
				"chart_line": [{ label: "Fator de Potência Trifásica", color: "blue", key: "" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => parseFloat(value).toFixed(2)
			},
			{
				"field": "tensao_fase_fase",
				"label": "Tensão Fase Fase",
				"type": "line",
				"chart_line": [{ label: "1", color: "green", key: "um" }, { label: "ALPHA", color: "blue", key: "alpha" }, { label: "ALPHA-1", color: "red", key: "alpha_um" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => value
			},
			{
				"field": "potencia_ativa_linha",
				"label": "Potência Ativa Linha",
				"type": "line",
				"chart_line": [{ label: "Potência RS", color: "green", key: "potencia_rs" }, { label: "Potência RT", color: "blue", key: "potencia_rt" }, { label: "Potência ST", color: "red", key: "potencia_st" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => value
			},
			{
				"field": "potencia_reativa_linha",
				"label": "Potência Reativa Linha",
				"type": "line",
				"chart_line": [{ label: "Potência RS", color: "green", key: "potencia_rs" }, { label: "Potência RT", color: "blue", key: "potencia_rt" }, { label: "Potência ST", color: "red", key: "potencia_st" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => value
			},
			{
				"field": "potencia_aparente_linha",
				"label": "Potência Aparente Linha",
				"type": "line",
				"chart_line": [{ label: "Potência RS", color: "green", key: "potencia_rs" }, { label: "Potência RT", color: "blue", key: "potencia_rt" }, { label: "Potência ST", color: "red", key: "potencia_st" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => value
			},
			{
				"field": "thd_tensao_fase",
				"label": "THD Tensão Fase",
				"type": "line",
				"chart_line": [{ label: "THD 1", color: "green", key: "thd_um" }, { label: "THD 2", color: "blue", key: "thd_dois" }, { label: "THD 3", color: "red", key: "thd_tres" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => value
			},
			{
				"field": "thd_corrente_fase",
				"label": "THD Corrente Fase",
				"type": "line",
				"chart_line": [{ label: "THD 1", color: "green", key: "thd_um" }, { label: "THD 2", color: "blue", key: "thd_dois" }, { label: "THD 3", color: "red", key: "thd_tres" }],
				"chart_reference_line": [],
				"block": 1,
				"tooltip_formatter": (value) => value
			},
		]
	}

	buscar_dados_relatario = () => {

		this.setState({ carregando_dados: true, create_pdf_link: false })

		let not_validate = false
		let valiate_form = this.state.validacao_formulario
		for (let key in this.state.detalhesDefault) {
			console.log(this.state.detalhesDefault[key])
			if (this.state.detalhesDefault[key] === "" || this.state.detalhesDefault[key] === null) {
				valiate_form[key] = false
				not_validate = true
			} else {
				valiate_form[key] = true
			}

		}

		if (not_validate === true) {
			this.setState({ validacao_formulario: valiate_form, carregando_dados: false })
			return
		}

		setTimeout(() => this.setState({ carregando_dados: false }), 3000)

		let userInfo = auth.getUserInfo();
		let bodyFormData = new FormData()
		let ticks = this.state.ticks
		let tickFormatter = this.state.tickFormatter
		let labelFormatter = this.state.labelFormatter
		let new_reporte = []
		let i = 0

		let data = this.state.detalhesDefault.data.split('-')
		let data_ini = data[0].split('/')
		let data_fim = data[1].split('/')

		let startDateChart = new Date(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00");
		let endDateChart = new Date(data_fim[2] + "-" + data_fim[1] + "-" + data_fim[0] + " 23:00:00");

		let diffTime = Math.abs(endDateChart.getTime() - startDateChart.getTime());
		let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		if (diffDays > 1) {
			ticks = []
			for (i; i < diffDays; i++) {
				let temp_date = new Date(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00");
				ticks.push(temp_date.setDate(temp_date.getDate() + i) / 1000)
			}
			tickFormatter = (tick) => moment(tick * 1000).format('D')
			labelFormatter = (label) => moment.unix(label).format('D/M/YYYY')
		} else {
			ticks = []
			for (i; i < 24; i++) {
				let hour = String(i)
				if (i < 10) {
					hour = "0" + hour
				}
				ticks.push(moment(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " " + hour + ":00:00").format('X'))
			}
			tickFormatter = (tick) => moment(tick * 1000).format('HH:mm')
			labelFormatter = (label) => moment.unix(label).format('HH:mm:ss')
		}


		bodyFormData.append('timestamp_dispositivo_ini', data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00");
		bodyFormData.append('timestamp_dispositivo_fim', data_fim[2] + "-" + data_fim[1] + "-" + data_fim[0] + " 23:59:00");
		bodyFormData.append('id_dispositivo', this.state.detalhesDefault.item);

		axios({
			method: 'post',
			url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte/energia/',
			data: bodyFormData,
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
		})
			.then((response) => {
				console.log(response.data)
				let reporte = response.data
				let tensao = []
				let corrente = []
				let diffResultado = false
				/*response.data.forEach((rp) => {
					tensao.push({"t": rp.timestamp_servidor, "Fase RS": rp.tensao[0], "Fase ST": rp.tensao[1], "Fase RT": rp.tensao[2] })
					corrente.push({"t": rp.timestamp_servidor, "Fase RS": rp.corrente[0], "Fase ST": rp.corrente[1], "Fase RT": rp.corrente[2] })
				})*/

				reporte = reporte.reverse()

				//SE ESTIVER VENDO MAIS E 1 DIA IREI TRAZER O MAIOR VALOR DO DIA
				if (diffDays > 1) {
					let temp_reporte = []
					let sum_to_check = 1
					for (i = 0; i < reporte.length; i++) {
						if (i >= (reporte.length - 1)) {
							sum_to_check = 0
						}
						if (temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD')] === undefined && sum_to_check === 1) {
							temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD')] = { ativa: reporte[i]['energia_ativa'] }
						} else if (temp_reporte[moment(reporte[i + sum_to_check]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD')] === undefined && sum_to_check === 1) {
							diffResultado = reporte[i + sum_to_check]['energia_ativa'] - temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD')]['ativa']
							temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD')] = { reativa: 0, ativa: diffResultado }
						} else if (sum_to_check === 0) {
							diffResultado = reporte[i]['energia_ativa'] - temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD')]['ativa']
							temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD')] = { reativa: 0, ativa: diffResultado }
						}

						//SEPARANDO A VALORES POR FASE

						if ("tensao" in reporte[i]) {
							reporte[i]['tensao__fase_rs'] = reporte[i]['tensao'][0]
							reporte[i]['tensao__fase_rt'] = reporte[i]['tensao'][1]
							reporte[i]['tensao__fase_st'] = reporte[i]['tensao'][2]
						}
						if ("corrente" in reporte[i]) {
							reporte[i]['corrente__fase_rs'] = reporte[i]['corrente'][0]
							reporte[i]['corrente__fase_rt'] = reporte[i]['corrente'][1]
							reporte[i]['corrente__fase_st'] = reporte[i]['corrente'][2]
						}

					}
					for (let key in temp_reporte) {
						if (key === 0) {
							new_reporte.push({ t: moment(key).format('X'), Ativa: 0, Reativa: 0 })
						}
						new_reporte.push({ t: moment(key).format('X'), Ativa: temp_reporte[key]['ativa'], Reativa: temp_reporte[key]['reativa'] })
					}
				} else {
					let temp_reporte = []
					let temp_tensao = []
					let temp_corrente = []
					let sum_to_check = 1

					for (i = 0; i < reporte.length; i++) {

						if (i >= (reporte.length - 1)) {
							sum_to_check = 0
						}

						//PEGA A DIFERENÇA DO INICIO E FIM DE UM PERIODO DE DETERMINADO CAMPO
						if (temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')] === undefined && sum_to_check === 1) {

							//INCIANDO CONSUMO DE ENERGIA NO PERIODO
							temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')] = { ativa: reporte[i]['energia_ativa'] }
							console.log("DATA INICIAL:", moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00'), { ativa: reporte[i]['energia_ativa'] })

						} else if (temp_reporte[moment(reporte[i + sum_to_check]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')] === undefined && sum_to_check === 1) {

							//FINALIZADO CONSUMO DE ENERGIA NO PERIODO
							diffResultado = reporte[i + sum_to_check]['energia_ativa'] - temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['ativa']
							temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')] = { reativa: 0, ativa: diffResultado }
							console.log("DATA INICIAL:", moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00'), { reativa: 0, ativa: diffResultado })

						} else if (sum_to_check === 0) {

							//FINALIZADO ULTIMA POSICAO
							console.log(moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00'))
							diffResultado = reporte[i]['energia_ativa'] - temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['ativa']
							temp_reporte[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')] = { reativa: 0, ativa: diffResultado }

						}

						//CALCULANDO A MÉDIA DE TENSAO E CORRENTE,
						if ('tensao' in reporte[i]) {

							if (temp_tensao[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')] === undefined) {

								temp_tensao[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')] = { tensao_00: [], tensao_01: [], tensao_02: [] }
								temp_tensao[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['tensao_00'].push(reporte[i]['tensao'][0])
								temp_tensao[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['tensao_01'].push(reporte[i]['tensao'][1])
								temp_tensao[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['tensao_02'].push(reporte[i]['tensao'][2])

							} else {

								temp_tensao[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['tensao_00'].push(reporte[i]['tensao'][0])
								temp_tensao[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['tensao_01'].push(reporte[i]['tensao'][1])
								temp_tensao[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['tensao_02'].push(reporte[i]['tensao'][2])

							}
						}

						//CALCULANDO A MÉDIA DE TENSAO E CORRENTE,
						if ('corrente' in reporte[i]) {
							if (temp_corrente[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')] === undefined) {

								temp_corrente[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')] = { corrente_00: [], corrente_01: [], corrente_02: [] }
								temp_corrente[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['corrente_00'].push(reporte[i]['corrente'][0])
								temp_corrente[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['corrente_01'].push(reporte[i]['corrente'][1])
								temp_corrente[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['corrente_02'].push(reporte[i]['corrente'][2])

							} else {

								temp_corrente[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['corrente_00'].push(reporte[i]['corrente'][0])
								temp_corrente[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['corrente_01'].push(reporte[i]['corrente'][1])
								temp_corrente[moment(reporte[i]['timestamp_dispositivo'] * 1000).format('YYYY-MM-DD HH:00:00')]['corrente_02'].push(reporte[i]['corrente'][2])

							}
						}

						//SEPARANDO A VALORES POR FASE
						if ("tensao" in reporte[i]) {
							reporte[i]['tensao__fase_rs'] = reporte[i]['tensao'][0]
							reporte[i]['tensao__fase_rt'] = reporte[i]['tensao'][1]
							reporte[i]['tensao__fase_st'] = reporte[i]['tensao'][2]
						}

						if ("corrente" in reporte[i]) {
							reporte[i]['corrente__fase_rs'] = reporte[i]['corrente'][0]
							reporte[i]['corrente__fase_rt'] = reporte[i]['corrente'][1]
							reporte[i]['corrente__fase_st'] = reporte[i]['corrente'][2]
						}
					}

					for (let key_one_day in temp_reporte) {
						if (key_one_day === 0) {
							new_reporte.push({ t: moment(key_one_day).format('X'), Ativa: 0, Reativa: 0 })
						}
						new_reporte.push({ t: moment(key_one_day).format('X'), Ativa: temp_reporte[key_one_day]['ativa'], Reativa: temp_reporte[key_one_day]['reativa'] })
					}

					//ENCONTRA A MÉDIA DA TENSAO NO PERIODO
					for (let key_tensao in temp_tensao) {

						let sum_array = (total, num) => total + num

						tensao.push({
							t: moment(key_tensao).format('X'),
							"Fase RS": temp_tensao[key_tensao]['tensao_00'].reduce(sum_array) / temp_tensao[key_tensao]['tensao_00'].length,
							"Fase ST": temp_tensao[key_tensao]['tensao_01'].reduce(sum_array) / temp_tensao[key_tensao]['tensao_01'].length,
							"Fase RT": temp_tensao[key_tensao]['tensao_02'].reduce(sum_array) / temp_tensao[key_tensao]['tensao_02'].length
						})

					}

					//ENCONTRA A MÉDIA DA CORRENTE NO PERIODO
					for (let key_corrente in temp_corrente) {

						let sum_array = (total, num) => total + num

						corrente.push({
							t: moment(key_corrente).format('X'),
							"Fase RS": temp_corrente[key_corrente]['corrente_00'].reduce(sum_array) / temp_corrente[key_corrente]['corrente_00'].length,
							"Fase ST": temp_corrente[key_corrente]['corrente_01'].reduce(sum_array) / temp_corrente[key_corrente]['corrente_01'].length,
							"Fase RT": temp_corrente[key_corrente]['corrente_02'].reduce(sum_array) / temp_corrente[key_corrente]['corrente_02'].length
						})
					}
				}

				let data_to_csv = []
				data_to_csv = response.data.map(dt => {

					return {
						c1: String(this.convertToDate(dt.timestamp_dispositivo)).substring(5, 0),
						c2: String(this.convertToDate(dt.timestamp_dispositivo)).slice(6, 14),
						c3: ("tensao" in dt) ? (typeof (dt.tensao[0]) !== "undefined") ? (dt.tensao[0]) : 0 : 0,
						c4: ("tensao" in dt) ? (typeof (dt.tensao[1]) !== "undefined") ? (dt.tensao[1]) : 0 : 0,
						c5: ("tensao" in dt) ? (typeof (dt.tensao[2]) !== "undefined") ? (dt.tensao[2]) : 0 : 0,
						c6: ("tensao" in dt) ? (typeof (dt.corrente[0]) !== "undefined") ? (dt.corrente[0]) : 0 : 0,
						c7: ("tensao" in dt) ? (typeof (dt.corrente[1]) !== "undefined") ? (dt.corrente[1]) : 0 : 0,
						c8: ("tensao" in dt) ? (typeof (dt.corrente[2]) !== "undefined") ? (dt.corrente[2]) : 0 : 0,
						c9: dt.frequencia ? dt.frequencia : 0,
						c10: dt.tensao_fase_fase ? Number(dt.tensao_fase_fase).toFixed(3) : 0,
						c11: dt.tensao_linha ? Number(dt.tensao_linha).toFixed(3) : 0,
						c12: dt.corrente_trifasica ? Number(dt.corrente_trifasica).toFixed(3) : 0,
						c13: dt.corrente_neutro ? Number(dt.corrente_neutro).toFixed(3) : 0,
						c14: dt.corrente_linha ? Number(dt.corrente_linha).toFixed(3) : 0,
						c15: dt.potencia_ativa_trifasica ? Number(dt.potencia_ativa_trifasica).toFixed(3) : 0,
						c16: dt.potencia_ativa_linha ? Number(dt.potencia_ativa_linha).toFixed(3) : 0,
						c17: dt.potencia_reativa_trifasica ? Number(dt.potencia_reativa_trifasica).toFixed(3) : 0,
						c18: dt.potencia_reativa_linha ? Number(dt.potencia_reativa_linha).toFixed(3) : 0,
						c19: dt.potencia_aparente_trifasica ? Number(dt.potencia_aparente_trifasica).toFixed(3) : 0,
						c20: dt.potencia_aparente_linha ? Number(dt.potencia_aparente_linha).toFixed(3) : 0,
						c21: dt.fator_potencia_trifasica ? Number(dt.fator_potencia_trifasica).toFixed(3) : 0,
						c22: dt.fator_potencia_linha ? Number(dt.fator_potencia_linha).toFixed(3) : 0,
						c23: dt.tensao_trifasica ? Number(dt.tensao_trifasica).toFixed(3) : 0,
						c24: dt.pulso ? Number(dt.pulso).toFixed(3) : 0,
						c25: dt.quatro_a_vinte ? Number(dt.quatro_a_vinte).toFixed(3) : 0,
						c26: dt.energia_ativa ? dt.energia_ativa : 0,
						c27: dt.maxima_demanda_ativa ? Number(dt.maxima_demanda_ativa).toFixed(3) : 0,
						c28: dt.demanda_ativa ? Number(dt.demanda_ativa).toFixed(3) : 0,
						c29: dt.maxima_demanda_aparente ? Number(dt.maxima_demanda_aparente).toFixed(3) : 0,
						c30: dt.demanda_aparente ? Number(dt.demanda_aparente).toFixed(3) : 0,
						c31: dt.versao ? Number(dt.versao).toFixed(3) : 0,
						c32: dt.temperatura ? Number(dt.temperatura).toFixed(3) : 0,
						c33: dt.energia_ativa_negativa ? Number(dt.energia_ativa_negativa).toFixed(3) : 0,
						c34: dt.frequencia_iec_10s ? Number(dt.frequencia_iec_10s).toFixed(3) : 0,
						c35: dt.thd_tensao_fase ? Number(dt.thd_tensao_fase).toFixed(3) : 0,
						c36: dt.thd_corrente_fase ? Number(dt.thd_corrente_fase).toFixed(3) : 0,
					}
				})

				this.setState({
					reporte: (diffDays > 1) ? new_reporte : new_reporte,
					dados: (reporte.length > 0) ? reporte : [{}],
					tensao: tensao,
					corrente: corrente,
					data: response.data,
					resumo: [],
					ticks: ticks,
					tickFormatter: tickFormatter,
					tickDomain: { start: parseInt(moment(data_ini[2] + "-" + data_ini[1] + "-" + data_ini[0] + " 00:00:00").format('X')), end: parseInt(moment(data_fim[2] + "-" + data_fim[1] + "-" + data_fim[0] + " 23:00:00").format('X')) },
					labelFormatter: labelFormatter,
					carregando_dados: false,
					relatorio_gerado: true,
					dataEletrico: data_to_csv
				})
				//this.getDadosEletricos()
			})
			.catch((error) => {
				console.log(error);
			})
	}

	zoom_chart = (e) => {
		let pr_tri = this.state.pr_tri
		this.setState({ tickDomain: { start: pr_tri.refAreaLeft, end: pr_tri.refAreaRight }, pr_tri: { refAreaLeft: false, refAreaRight: false } })
	}

	getBase64FromImageUrl = () => {

		let img = document.getElementById('logomarca')

		let canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;

		let ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0);

		let dataURL = canvas.toDataURL("image/png");

		return dataURL;

	}

	generateImage = () => {
		this.setState({ relatorio_gerado: false, gerando_pdf: true })
		let pdfData = this.state.pdfData

		PNG.svgAsPngUri(ReactDOM.findDOMNode(this.energiaChart).children[0], {}, (pngBase64Vaz) => {
			pdfData.consumo_energia_image = pngBase64Vaz
			this.setState(pdfData)
		});

		PNG.svgAsPngUri(ReactDOM.findDOMNode(this.energiaTensao).children[0], {}, (pngBase64Vol) => {
			pdfData.energia_tensao_image = pngBase64Vol
			this.setState(pdfData)
		});

		PNG.svgAsPngUri(ReactDOM.findDOMNode(this.energiaCorrente).children[0], {}, (pngBase64Vol) => {
			pdfData.energia_corrente_image = pngBase64Vol
			this.setState(pdfData)
		});

		let requestArray = []
		let userInfo = auth.getUserInfo();
		let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

		requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-sistema/', config_me))

		axios.all(requestArray)
			.then(axios.spread((config) => {
				console.log(config)
				let detalhes_da_empresa = this.state.detalhes_da_empresa
				console.log(detalhes_da_empresa)
				if (config.status === 200) {
					detalhes_da_empresa.logomarca = cfg.base_api_url + 'media/' + config.data.data.logomarca
					detalhes_da_empresa.endereco = config.data.data.endereco
					detalhes_da_empresa.telefone = config.data.data.telefone
					detalhes_da_empresa.email = config.data.data.email_contato
					detalhes_da_empresa.descricao_horario_atendimento = config.data.data.descricao_horario_atendimento
				}

				detalhes_da_empresa.logomarca = this.getBase64FromImageUrl(detalhes_da_empresa.logomarca)

				this.setState({ detalhes_da_empresa, create_pdf_link: true })

				setTimeout(() => {

					let elm = document.getElementById('download-pdf').firstChild
					console.log(elm.href)
					window.open(elm.href, "_blank")

					this.setState({ relatorio_gerado: true, gerando_pdf: false })

				}, 5000);

			}));
	}

	getDadosEletricos = () => {
		if (this.state.relatorio_gerado) {
			let data = false
			data = this.state.data.map(dt => {
				return {
					c1: String(this.convertToDate(dt.timestamp_dispositivo)).substring(5, 0),
					c2: String(this.convertToDate(dt.timestamp_dispositivo)).slice(6, 14),
					c3: dt.tensao[0],
					c4: dt.tensao[1],
					c5: dt.tensao[2],
					c6: dt.corrente[0],
					c7: dt.corrente[1],
					c8: dt.corrente[2],
					c9: dt.frequencia ? dt.frequencia : 0,
					c10: dt.tensao_fase_fase ? Number(dt.tensao_fase_fase).toFixed(3) : 0,
					c11: dt.tensao_linha ? Number(dt.tensao_linha).toFixed(3) : 0,
					c12: dt.corrente_trifasica ? Number(dt.corrente_trifasica).toFixed(3) : 0,
					c13: dt.corrente_neutro ? Number(dt.corrente_neutro).toFixed(3) : 0,
					c14: dt.corrente_linha ? Number(dt.corrente_linha).toFixed(3) : 0,
					c15: dt.potencia_ativa_trifasica ? Number(dt.potencia_ativa_trifasica).toFixed(3) : 0,
					c16: dt.potencia_ativa_linha ? Number(dt.potencia_ativa_linha).toFixed(3) : 0,
					c17: dt.potencia_reativa_trifasica ? Number(dt.potencia_reativa_trifasica).toFixed(3) : 0,
					c18: dt.potencia_reativa_linha ? Number(dt.potencia_reativa_linha).toFixed(3) : 0,
					c19: dt.potencia_aparente_trifasica ? Number(dt.potencia_aparente_trifasica).toFixed(3) : 0,
					c20: dt.potencia_aparente_linha ? Number(dt.potencia_aparente_linha).toFixed(3) : 0,
					c21: dt.fator_potencia_trifasica ? Number(dt.fator_potencia_trifasica).toFixed(3) : 0,
					c22: dt.fator_potencia_linha ? Number(dt.fator_potencia_linha).toFixed(3) : 0,
					c23: dt.tensao_trifasica ? Number(dt.tensao_trifasica).toFixed(3) : 0,
					c24: dt.pulso ? Number(dt.pulso).toFixed(3) : 0,
					c25: dt.quatro_a_vinte ? Number(dt.quatro_a_vinte).toFixed(3) : 0,
					c26: dt.energia_ativa ? dt.energia_ativa : 0,
					c27: dt.maxima_demanda_ativa ? Number(dt.maxima_demanda_ativa).toFixed(3) : 0,
					c28: dt.demanda_ativa ? Number(dt.demanda_ativa).toFixed(3) : 0,
					c29: dt.maxima_demanda_aparente ? Number(dt.maxima_demanda_aparente).toFixed(3) : 0,
					c30: dt.demanda_aparente ? Number(dt.demanda_aparente).toFixed(3) : 0,
					c31: dt.versao ? Number(dt.versao).toFixed(3) : 0,
					c32: dt.temperatura ? Number(dt.temperatura).toFixed(3) : 0,
					c33: dt.energia_ativa_negativa ? Number(dt.energia_ativa_negativa).toFixed(3) : 0,
					c34: dt.frequencia_iec_10s ? Number(dt.frequencia_iec_10s).toFixed(3) : 0,
					c35: dt.thd_tensao_fase ? Number(dt.thd_tensao_fase).toFixed(3) : 0,
					c36: dt.thd_corrente_fase ? Number(dt.thd_corrente_fase).toFixed(3) : 0,
				}
			})
			this.setState({ dataEletrico: data })
		}
	}

	convertToDate(stamp) {
		return moment(stamp * 1000).format('DD/MM H:mm:ss')
	}

	retorna_pdf_link = () => {
		return (
			<PDFDownloadLink document={<PdfDocument data={{ "descricao_chart": "Consumo de Energia", "charts": this.state.pdfData, "resumo": { minimo: 0, maximo: 0 }, "detalhes_da_empresa": this.state.detalhes_da_empresa, "unidade_selecionada": this.state.unidade_selecionada }} />} fileName="test.pdf">
			</PDFDownloadLink>
		)
	}

	generate_chart = () => {

		return this.state.campos_dados_eletricos.map((campo, key) => {
			let returnJsx = null

			if (!(campo.field in this.state.dados[0])) {
				return returnJsx
			}

			switch (campo.type) {
				case "bar":
					returnJsx = (
						<div key={key} className={"col-md-" + String(campo.block * 6)}>

							<h5 style={{ textAlign: 'center' }}>{campo.label}</h5>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<BarChart
										height={300}
										data={this.state.reporte}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
										ref={(chart) => this.energiaConsumoChart = chart}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="t"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20, top: 20, bottom: 20, }}
										/>
										<YAxis
											allowDataOverflow={true}
											padding={{ top: 90 }}
										/>
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={campo.tooltip_formatter}
										/>
										<Legend />
										{
											campo.chart_line.map((line, key) => {
												return <Bar dataKey={line.key} key={key} stackId={"a"} fill={line.color} label={<CustomizedLabel />} />
											})
										}
									</BarChart>
								</ResponsiveContainer>
							</div>

						</div>
					)
					break;
				case "line":

					returnJsx = (
						<div key={key} className={"col-md-" + String(campo.block * 6)}>
							<h5 style={{ textAlign: 'center' }}>{campo.label}</h5>
							<div style={{ width: '100%', height: 300 }}>
								<ResponsiveContainer>
									<LineChart
										ref={(chart) => this.chart = chart}
										width={500}
										height={300}
										data={this.state.dados}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="timestamp_dispositivo"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={campo.tooltip_formatter}
										/>
										<Legend />
										{
											campo.chart_line.map((line, key) => {
												return <Line key={key} type="monotone" name={line.label} dataKey={(campo.field + ((line.key) ? "__" + line.key : ""))} stroke={line.color} dot={false} />
											})
										}
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>
					)
					break;
				default:
					break;
			}
			return returnJsx
		})

	}

	render() {
		const { dataEletrico } = this.state

		const columns = [
			{ id: 'c1', displayName: 'Data' },
			{ id: 'c2', displayName: 'Hora' },
			{ id: 'c3', displayName: 'Tensao L1' },
			{ id: 'c4', displayName: 'Tensao L2' },
			{ id: 'c5', displayName: 'Tensao L3' },
			{ id: 'c6', displayName: 'Corrente L1' },
			{ id: 'c7', displayName: 'Corrente L2' },
			{ id: 'c8', displayName: 'Corrente L3' },
			{ id: 'c9', displayName: 'Frequencia' },
			{ id: 'c10', displayName: 'Tensao Fase-a-fase' },
			{ id: 'c11', displayName: 'Tensao Linha' },
			{ id: 'c12', displayName: 'Corrente Trifasica' },
			{ id: 'c13', displayName: 'Corrente Neutro' },
			{ id: 'c14', displayName: 'Corrente Linha' },
			{ id: 'c15', displayName: 'Potencia Ativa Trifasica' },
			{ id: 'c16', displayName: 'Potencia Ativa Linha' },
			{ id: 'c17', displayName: 'Potencia Reativa Trifasica' },
			{ id: 'c18', displayName: 'Potencia Reativa Linha' },
			{ id: 'c19', displayName: 'Potencia Aparente Trifasica' },
			{ id: 'c20', displayName: 'Potencia Aparente Linha' },
			{ id: 'c21', displayName: 'Fator Potencia Trifasica' },
			{ id: 'c22', displayName: 'Fator Potencia Linha' },
			{ id: 'c23', displayName: 'Tensao Trifasica' },
			{ id: 'c24', displayName: 'Pulso' },
			{ id: 'c25', displayName: '4a20' },
			{ id: 'c26', displayName: 'Energia Ativa' },
			{ id: 'c27', displayName: 'Maxima Demanda Ativa' },
			{ id: 'c28', displayName: 'Demanda Ativa' },
			{ id: 'c29', displayName: 'Maxima Demanda Aparente' },
			{ id: 'c30', displayName: 'Demanda Aparente' },
			{ id: 'c31', displayName: 'Versao' },
			{ id: 'c32', displayName: 'Temperatura' },
			{ id: 'c33', displayName: 'Energia Ativa Negativa' },
			{ id: 'c34', displayName: 'Frequencia IEC 10 Segundos' },
			{ id: 'c35', displayName: 'THD tensao fase' },
			{ id: 'c36', displayName: 'THD corrente de fase' }
		]

		return (
			<div className="hdv-render-div">
				<div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
					<h3>Relatório de Dados Elétricos</h3>
					<div id="download-pdf">
						{(this.state.create_pdf_link === true) ? this.retorna_pdf_link() : ""}
					</div>
					<div className="row hdv-header-filters">
						<HeaderReportDefault DataChange={this.state.detalhesDefault} fn={false} validacao={this.state.validacao_formulario} />
						<div className="col-md-12">
							<button
								disabled={this.state.carregando_dados}
								className={(this.state.carregando_dados === true) ? "hdv-header-filters-button hdv-generate-report hdv-block-button" : "hdv-header-filters-button hdv-generate-report"}
								onClick={this.buscar_dados_relatario} >Gerar Relatório</button>

							<button
								disabled={!this.state.relatorio_gerado}
								className={(this.state.relatorio_gerado === false) ? "hdv-header-filters-button hdv-generate-pdf hdv-block-button" : "hdv-header-filters-button hdv-generate-pdf hdv-block-pdf-border"}
								onClick={this.generateImage} > <i className="fa fa-file-pdf-o fa-20px"></i> Exportar PDF</button>

							<button
								style={{ backgroundColor: '#4da845', width: 'fit-content', height: '42px', margin: '15px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}
								className={(this.state.relatorio_gerado ? "" : "hdv-block-button")}
								disabled={!this.state.relatorio_gerado}>
								{this.state.relatorio_gerado
									?
									<CsvDownloader
										columns={columns}
										datas={dataEletrico}
										filename="rel_dados_eletricos.csv"
										separator=";">
										<p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '8px 10px' }}>
											<i className="fa fa-file fa-20px"></i> Exportar CSV</p>
									</CsvDownloader>
									:
									<p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '8px 10px' }}>
										<i className="fa fa-file fa-20px"></i>
										Exportar CSV
									</p>
								}
							</button>
							<ReactLoading className={(this.state.gerando_pdf === true) ? "hdv-report-loading-left" : "hdv-noshow-item"} type="cylon" color="skyblue" height={15} width={50} />

							<ReactLoading className={(this.state.carregando_dados === true) ? "hdv-report-loading" : "hdv-noshow-item"} type="cylon" color="#589bd4" height={15} width={50} />
						</div>
					</div>

					<div className={(this.state.data.length > 0) ? "row" : "row hdv-noshow-item"}>
						<div className="col-md-3 hdv-noshow-item">
							<div className="hdv-header-summary">
								<h5>Resumo:</h5>
							</div>
							<br />

							<PieChart width={250} height={200} onMouseEnter={this.onPieEnter}>
								<Pie
									data={data2}
									cx={120}
									cy={100}
									outerRadius={60}
									innerRadius={40}
									fill="#8884d8"
									paddingAngle={0}
									dataKey="value"
								>
									{
										data.map((entry, index) => <Cell key={index} fill={COLORS2[index % COLORS2.length]} />)
									}
								</Pie>
								<Pie
									data={data}
									cx={120}
									cy={100}
									innerRadius={70}
									outerRadius={90}
									fill="#8884d8"
									paddingAngle={0}
									dataKey="value"
								>
									{
										data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
									}
								</Pie>
								<text fill="#e74c3c" className="textprin" x={125} y={100} textAnchor="middle" dominantBaseline="middle">
									75%
								</text>
								<text fill="#27ae60" className="textsub" x={125} y={120} textAnchor="middle" dominantBaseline="middle">
									24/Abril
								</text>
							</PieChart>

							<br />
							<div className="col-md-12 hdv-resumo-titulo">Média Diária: <strong>R$ 352,00</strong></div>
							<div className="col-md-12 hdv-resumo-titulo">Meta: <strong>R$ 4.550,50</strong> </div>
							<div className="col-md-12 hdv-resumo-titulo">Data Fechamento: <strong>29/Abril</strong></div>
						</div>

						{this.generate_chart()}

						{/*<div className="col-md-12">
							<h5 style={{ textAlign: 'center' }}>Gráfico de Consumo de Energia</h5>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<BarChart
										height={300}
										data={this.state.reporte}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
										ref={(chart) => this.energiaChart = chart}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="t"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis allowDataOverflow={true} />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(1).toString() + " " + this.state.unidade_selecionada}
										/>
										<Legend />
										<Bar dataKey="Reativa" stackId="a" fill="black" label={<CustomizedLabel />} />
										<Bar dataKey="Ativa" stackId="a" fill="#27ae60" label={<CustomizedLabel />} />
									</BarChart>
								</ResponsiveContainer>
							</div>

						</div>

						<div className="col-md-6">
							<h5 style={{ textAlign: 'center' }}>Gráfico de Tensão</h5>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer style={{padding: '10px'}}>
									<LineChart
										width={500}
										height={300}
										data={this.state.tensao}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
										ref={(chart) => this.energiaTensao = chart}>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="t"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(1).toString() + " v"}
										/>
										<Legend onClick={(e) => console.log(e)} />
										<Line type="monotone" dataKey="Fase RS" stroke="#8884d8" activeDot={{ r: 8 }}/>
										<Line type="monotone" dataKey="Fase RT" stroke="#82ca9d" />
										<Line type="monotone" dataKey="Fase ST" stroke="red" />
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>
						<div className="col-md-6">
							<h5 style={{ textAlign: 'center' }}>Gráfico de Corrente</h5>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<LineChart
										width={500}
										height={300}
										data={this.state.corrente}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
										ref={(chart) => this.energiaCorrente = chart}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="t"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(1).toString() + " (A)"}
										/>
										<Legend onClick={(e) => console.log(e)} />
										<Line type="monotone" dataKey="Fase RS" stroke="#8884d8" activeDot={{ r: 8 }} />
										<Line type="monotone" dataKey="Fase RT" stroke="#82ca9d" />
										<Line type="monotone" dataKey="Fase ST" stroke="red" />
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>

						<div className="col-md-6">
							<h5 style={{ textAlign: 'center' }}>Corrente Trifásica</h5>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<LineChart
										width={500}
										height={300}
										data={this.state.dados}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
										ref={(chart) => this.energiaCorrenteTrifasica = chart}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="timestamp_servidor"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis domain={[0, 15]} />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(1).toString() + " (A)"}
										/>
										<Legend onClick={(e) => console.log(e)} />
										<Line type="monotone" dataKey="corrente_trifasica" stroke="#8884d8" dot={false} />
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>

						<div className="col-md-6">
							<h5 style={{ textAlign: 'center' }}>Tensão Trifásica</h5>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<LineChart
										width={500}
										height={300}
										data={this.state.dados}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
										ref={(chart) => this.energiaTensaoTrifasica = chart}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="timestamp_servidor"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis domain={[0, 15]} />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(1).toString() + " (A)"}
										/>
										<Legend onClick={(e) => console.log(e)} />
										<Line type="monotone" dataKey="tensao_trifasica" stroke="#8884d8" dot={false} />
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>

						<div className="col-md-6">
							<h5 style={{ textAlign: 'center' }}>Potência Ativa Trifásica</h5>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<LineChart
										width={500}
										height={300}
										data={this.state.dados}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
										ref={(chart) => this.energiaAtivaTrifasica = chart}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="timestamp_servidor"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis domain={[0, 15]} />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(1).toString() + " (A)"}
										/>
										<Legend onClick={(e) => console.log(e)} />
										<Line type="monotone" dataKey="potencia_ativa_trifasica" stroke="#8884d8" dot={false} />
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>

						<div className="col-md-6">
							<h5 style={{ textAlign: 'center' }}>Potência Reativa Trifásica</h5>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<LineChart
										width={500}
										height={300}
										data={this.state.dados}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
										ref={(chart) => this.energiaReativaTrifasica = chart}
									//onMouseDown = { (e) => {let data = this.state; data['pr_tri']['refAreaLeft'] = e.activeLabel; this.setState(data) } }
									//onMouseMove = { (e) => {let data = this.state; data['pr_tri']['refAreaRight'] = e.activeLabel; data.pr_tri.refAreaLeft && this.setState(data) } }
									//onMouseUp = { this.zoom_chart }
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="timestamp_servidor"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis domain={[0, 15]} yAxisId="1" />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(1).toString() + " (A)"}
										/>
										<Legend onClick={(e) => console.log(e)} />
										<Line yAxisId="1" type="monotone" dataKey="potencia_reativa_trifasica" stroke="#8884d8" dot={false} />
										{
											(this.state.pr_tri.refAreaLeft && this.state.pr_tri.refAreaRight) ? (<ReferenceArea yAxisId="1" x1={this.state.pr_tri.refAreaLeft} x2={this.state.pr_tri.refAreaRight} strokeOpacity={0.3} />) : null
										}
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>

						<div className="col-md-6">
							<h5 style={{ textAlign: 'center' }}>Demanda Ativa</h5>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<LineChart
										width={500}
										height={300}
										data={this.state.dados}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
										ref={(chart) => this.energiaDemandaAtivaTrifasica = chart}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="timestamp_servidor"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis domain={[0, 15]} />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(1).toString() + " (A)"}
										/>
										<Legend onClick={(e) => console.log(e)} />
										<Line type="monotone" dataKey="demanda_ativa" stroke="#8884d8" dot={false} />
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>

						<div className="col-md-6">
							<h5 style={{ textAlign: 'center' }}>Fator de Potência Trifásica</h5>
							<div style={{ width: '100%', height: 500 }}>
								<ResponsiveContainer>
									<LineChart
										width={500}
										height={300}
										data={this.state.dados}
										margin={{
											top: 5, right: 30, left: 20, bottom: 5,
										}}
										ref={(chart) => this.energiaFatorPotenciaTrifasica = chart}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="timestamp_servidor"
											type="number"
											tickFormatter={this.state.tickFormatter}
											domain={[this.state.tickDomain.start, this.state.tickDomain.end]}
											ticks={this.state.ticks}
											padding={{ left: 20, right: 20 }}
										/>
										<YAxis domain={[0, 15]} />
										<Tooltip
											labelFormatter={this.state.labelFormatter}
											formatter={(value) => parseFloat(value).toFixed(1).toString() + " (A)"}
										/>
										<Legend onClick={(e) => console.log(e)} />
										<Line type="monotone" dataKey="fator_potencia_trifasica" stroke="#8884d8" dot={false} />
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>*/}

					</div>

				</div>
			</div>
		);
	}
}

export default RelatorioEnergia;