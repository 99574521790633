import React, { Component } from 'react'
import ReactLoading from 'react-loading'
import CsvDownloader from 'react-csv-downloader'
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import Select from 'react-select'
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap'
import * as rdrLocales from 'react-date-range/dist/locale'
import { DateRangePicker } from 'react-date-range'
import moment from 'moment'


export default class Relatorio extends Component {
    userInfo = auth.getUserInfo()
    config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
    baseUrl = cfg.base_api_url + cfg.api_version

    constructor() {
        super()
        this.state = {
            detalhesDefault: { "data": "", "codigo": "" },
            sideccr: { usuario: '', codigo: '' },
            datePickerShow: false,
            dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
            validacao_formulario: { data: true, codigo: true },
            reporte: { reporte: [], entrada_digital_descricao: '', entrada_digital_id: '' },
            csv: { data: [], columns: [] },
            carregandoDados: false,
            relatorioGerado: false,
            dataFormatada: '',
            codigoOptions: []
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.checarCalendarioAberto, false)

        if (this.props.match.params.id)
            this.getData()
    }

    checarCalendarioAberto = (e) => {
        if (this.state.checkClose === true) {
            let retArray = e.path.filter((path) => {
                if (path.className === "hdv-datepicker")
                    return true
                return false
            })

            if (retArray.length === 0)
                this.setState({ "datePickerShow": false, checkClose: false })

        } else this.setState({ checkClose: true })

        return true
    }

    handleSelect = (ranges) => {
        let detalhes = this.state.detalhesDefault
        detalhes.data = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
        this.setState({
            dateRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            },
            dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    handleMedidor = (selectedOption) => {
        let { detalhesDefault } = this.state
        detalhesDefault['codigo'] = selectedOption
        this.setState({ detalhesDefault })
    }

    showDatePicker = () => this.setState({ "datePickerShow": !this.state.datePickerShow })

    manipularData = (sentido) => {
        if (!this.state.detalhesDefault.data)
            return

        let diffTime = Math.abs(this.state.dateRange.endDate.getTime() - this.state.dateRange.startDate.getTime())
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

        diffDays = (diffDays === 0) ? 1 : diffDays
        diffDays = (sentido === "avancar") ? diffDays : -Math.abs(diffDays)

        let newStartDate = new Date(this.state.dateRange.startDate.setDate(this.state.dateRange.startDate.getDate() + diffDays))
        let newEndDate = new Date(this.state.dateRange.endDate.setDate(this.state.dateRange.endDate.getDate() + diffDays))

        let detalhes = this.state.detalhes
        detalhes.data = newStartDate.toLocaleDateString() + "-" + newEndDate.toLocaleDateString()

        this.setState({
            dateRange: { startDate: newStartDate, endDate: newEndDate, key: 'selection' },
            dataFormatada: newStartDate.toLocaleDateString() + " - " + newEndDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    getData() {
        axios.get(`${this.baseUrl}/${this.userInfo.empresa}/sideccr/${this.props.match.params.id}`, this.config_me)
            .then(res => {
                console.log(res.data)
                let { sideccr, detalhesDefault } = this.state
                let codigoOptions = []

                sideccr['chave'] = res.data.sideccr?.chave
                sideccr['usuario'] = res.data.sideccr?.usuario
                sideccr['enviarDados'] = res.data.sideccr?.ativo
                sideccr['codigo'] = res.data.sideccr?.codigo_medidor
                sideccr['dispositivo'] = res.data.sideccr?.id_dispositivo_id
                sideccr['entrada_digital'] = res.data.sideccr?.id_entrada_digital_id

                res.data.codigos?.map(codigo => codigoOptions.push({
                    label: codigo.codigo_medidor,
                    value: codigo.codigo_medidor
                }))

                if (codigoOptions.length > 0) {
                    detalhesDefault['codigo'] = codigoOptions[0]
                }

                this.setState({ sideccr: sideccr, codigoOptions: codigoOptions })
            })
    }

    buscarDadosRelatorio = async () => {
        const { detalhesDefault, validacao_formulario, sideccr } = this.state
        this.setState({ carregandoDados: true })
        let not_validate = false

        for (let key in detalhesDefault) {
            if (detalhesDefault[key] === "" || detalhesDefault[key] === null) {
                validacao_formulario[key] = false
                not_validate = true
            } else
                validacao_formulario[key] = true
        }

        if (not_validate === true) {
            this.setState({ validacao_formulario: validacao_formulario, carregandoDados: false })
            return
        }

        const formData = new FormData()
        let data = detalhesDefault.data.split('-')
        let data_inicio = new Date(data[0].split('/').reverse().join('-') + ' 00:00:00').getTime() / 1000
        let data_fim = new Date(data[1].split('/').reverse().join('-') + ' 23:59:59').getTime() / 1000

        formData.append('codigo_medidor', detalhesDefault.codigo.value)
        formData.append('usuario', sideccr.usuario)
        formData.append('data_inicio', data_inicio)
        formData.append('data_fim', data_fim)

        await axios({
            method: 'POST',
            url: `${cfg.base_api_url + cfg.api_version}/${this.userInfo.empresa}/relatorio-sideccr/${sideccr.dispositivo}/`,
            data: formData,
            ...this.config_me,
        })
            .then(res => {
                let { reporte } = this.state
                let csv = { data: [], columns: [] }
                if (res.data && res.data?.reporte) {
                    reporte = {
                        entrada_digital_descricao: res.data.entrada_digital_descricao,
                        entrada_digital_id: res.data.entrada_digital_descricao,
                        reporte: res.data.reporte ?? [],
                    }

                    if (reporte?.reporte?.length > 0) {
                        reporte?.reporte.map(dt => csv.data.push({
                            'data_fechado': this.convertToDate(dt.data_hora_fechado),
                            'data_utilizado': this.convertToDate(dt.data_hora_utlizado),
                            'vazao': Number(dt.vazao).toFixed(4),
                            'hidrometro': `#${dt.canal} - ${reporte.entrada_digital_descricao}`,
                            'resposta': dt.resposta_sideccr.mensagem_erro
                        })
                        )
                    }

                    csv.columns = [
                        { 'id': 'data_fechado', 'displayName': 'Data fechado' },
                        { 'id': 'data_utilizado', 'displayName': 'Data utilizado' },
                        { 'id': 'vazao', 'displayName': 'Vazão' },
                        { 'id': 'hidrometro', 'displayName': 'Hidrômetro' },
                        { 'id': 'resposta', 'displayName': 'Resposta' },
                    ]
                }

                this.setState({ reporte: reporte, csv: csv })
            })
            .catch(err => console.log(err))
        this.setState({ carregandoDados: false, relatorioGerado: true })
    }

    convertToDate = timestamp => moment(timestamp * 1000).format('D/M H:mm:ss')

    render() {
        const { relatorioGerado, reporte, sideccr, carregandoDados } = this.state

        return (
            <>
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <h3>Relatório SIDECC-R</h3>
                    <div className="row col-md-12" style={{ padding: '0', margin: '0' }}>
                        <div className="col-md-5 hdv-top15" style={{ padding: '0' }}>
                            <label style={{ margin: '0' }}>Periodo</label>
                            <InputGroup className="hdv-force-hight">
                                <InputGroupAddon addonType="prepend">
                                    <Button onClick={() => this.manipularData("voltar")} color="info">
                                        <i className="fa fa-angle-double-left"></i>
                                    </Button>
                                </InputGroupAddon>
                                <Input className="hdv-force-hight" placeholder="Selecione o periodo no botão 'Exibir Calendário' " onChange={() => false} disabled value={this.state.dataFormatada} />
                                <InputGroupAddon addonType="append">
                                    <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                        <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                        {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                    </Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType="append">
                                    <Button onClick={() => this.manipularData("avancar")} color="info">
                                        <i className="fa fa-angle-double-right"></i>
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                            <span className={!this.state.validacao_formulario.data ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                            <div className={this.state.datePickerShow ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                <DateRangePicker locale={rdrLocales.pt} ranges={[this.state.dateRange]} onChange={this.handleSelect} />
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-3 hdv-form-default" >
                            <label className="required">Usuário
                                <input type="text" value={sideccr.usuario} readOnly />
                            </label>
                        </div>

                        <div className="col-md-3 hdv-form-default" style={{ paddingRight: '0' }}>
                            <label className="required">Código do medidor
                                <Select
                                    value={this.state.detalhesDefault.codigo}
                                    options={this.state.codigoOptions}
                                    onChange={(e) => this.handleMedidor(e)}
                                />
                            </label>
                            <span className={!this.state.validacao_formulario.codigo ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                        </div>

                        <div className="col-md-12 d-flex justify-content-end px-0 align-items-center py-1">
                            <ReactLoading className={carregandoDados ? "hdv-report-loading pb-5" : "hdv-noshow-item"} type="cylon" color="#589bd4" height={15} width={50} />

                            <button
                                style={{ backgroundColor: '#4da845', width: 'fit-content', padding: '10px', margin: '12px 8px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}
                                className={(relatorioGerado ? "" : "hdv-block-button mx-2 my-0")}
                                disabled={!relatorioGerado}>
                                {relatorioGerado && this.state.csv.data.length > 0
                                    ?
                                    <CsvDownloader
                                        style={{ border: 'none' }}
                                        columns={this.state.csv.columns}
                                        datas={this.state.csv.data}
                                        filename={`relatorio_sideccr_${new Date().getTime()}.csv`}
                                        separator=";">
                                        <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '0 10px' }}>
                                            Exportar CSV
                                        </p>
                                    </CsvDownloader>
                                    :
                                    <p style={{ fontSize: '12px', margin: '0', color: '#fff', padding: '0 10px' }}>
                                        Exportar CSV
                                    </p>
                                }
                            </button>

                            <div style={{ display: 'flex', float: 'right' }}>
                                <button
                                    style={{ width: 'fit-content' }}
                                    disabled={this.state.carregandoDados}
                                    className={this.state.carregandoDados ? "my-0 hdv-header-filters-button hdv-generate-report hdv-block-button" : "hdv-header-filters-button hdv-generate-report my-0"}
                                    onClick={this.buscarDadosRelatorio} >Gerar Relatório
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {reporte?.reporte?.length > 0 ?
                            <div className={"col-md-12"}>
                                <table className="table table-sm sideccr-table">
                                    <thead>
                                        <tr>
                                            <th>Vazão</th>
                                            <th>Data fechado</th>
                                            <th>Data utilizado</th>
                                            <th>Hidrômetro</th>
                                            <th>Timestamp</th>
                                            <th>Resposta</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reporte?.reporte?.map((rep, index) => (
                                            <tr key={index}>
                                                <td hdv-title={rep.vazao}>{Number(rep.vazao).toFixed(4)}</td>
                                                <td>{this.convertToDate(rep.data_hora_fechado)}</td>
                                                <td>{this.convertToDate(rep.data_hora_utlizado)}</td>
                                                <td>#{rep.canal} - {reporte.entrada_digital_descricao}</td>
                                                <td>{rep.timestamp}</td>
                                                <td>{rep.resposta_sideccr.mensagem_erro.substring(0, 3)} - {rep.resposta_sideccr.codigo_erro}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            :
                            null
                        }
                        {relatorioGerado && reporte.reporte.length === 0 ?
                            <p style={{ textAlign: 'center', color: '#9c2f2f', fontWeight: 'bold' }}>
                                Relatório não gerado
                            </p>
                            :
                            null
                        }
                    </div>
                </div>
            </>
        )
    }
}
