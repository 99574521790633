import  React, { Component } from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Legend, Tooltip } from 'recharts';

class GraficoBarra extends Component {

    state = {
        params: {
            descricao: "",
            values: [],
            data_value: "",
            data_label: "",
            last_value: 0
        }
    }

    /*componentDidMount = () => {
        if(this.props.datawgt){
            let params_select = this.state.params
            params_select['descricao'] = this.props.datawgt['descricao']
            console.log(params_select)
            console.log(this.props.datawgt)
            if(this.props.datawgt['value'].name !== undefined){
                let data = this.props.datawgt['value']
                data['uv'] = 0
                params_select['values'].push(data)
                params_select['last_value'] = this.props.datawgt['value']
            }
            params_select['data_value'] = this.props.datawgt['data_value']
            params_select['data_label'] = this.props.datawgt['data_label']
            this.setState({params: params_select})
        }
    }*/

    componentWillReceiveProps = (nextProps) => {
        if(this.props.datawgt.value !== nextProps.datawgt.value){
            let params_select = this.state.params
            console.log(params_select)
            console.log(this.props.datawgt)
            params_select['descricao'] = this.props.datawgt['descricao']
            if(this.props.datawgt['value'].name !== 'undefined' && this.props.datawgt['value']['Volume'] !== 0){
                let getHour = params_select['values'].filter( (item) => {return item.name === this.props.datawgt['value'].name })[0]
                console.log(getHour)
                if(getHour){
                    params_select['values'] = params_select['values'].filter( (item) => {return item.name !== this.props.datawgt['value'].name })
                    getHour['Volume'] = getHour['Volume'] + (this.props.datawgt['value']['Volume'] - params_select['last_value']['Volume']) 
                    console.log('SOMANDO: ', getHour)
                    params_select['values'].push(getHour)
                }else{
                    let data = Object.assign({},this.props.datawgt['value'])
                    data['Volume'] = 0
                    console.log('ADICIONANDO: ', data)
                    params_select['values'].push(data)
                }
                params_select['last_value'] = this.props.datawgt['value']
            }
            params_select['data_value'] = this.props.datawgt['data_value']
            params_select['data_label'] = this.props.datawgt['data_label']
            if (params_select['values'].length > 12)
                params_select['values'].shift()
            this.setState({params: params_select})
        }
    }

    render() {
        console.log(this.state)
        return (

                <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                    <div className="hdv-top-bar-bloco">
                        {(this.state.params.comunicacao === false) 
                        ? 
                        <i style={{color: "limegreen", zIndex: 999999, position: "relative"}} alt="Informação Atualizada"  title="Informação Atualizada" className="fa fa-square-full"></i>
                        :    
                        <i style={{color: "tomato", zIndex: 999999, position: "relative"}} alt="Informação com Atraso"  title="Informação com Atraso" className="fa fa-square-full"></i>
                        }
                        {decodeURI(this.state.params.descricao)}
                    </div>
                    <ResponsiveContainer width="100%">
                        <BarChart key={this.state.params.values[this.state.params.values.length-1]?.value} data={this.state.params.values} margin={{top: 20, right: 50, left: 20, bottom: 50}}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                label={{ value: "Pages", position: "insideBottomRight", dy: 10}}
                                dataKey={this.state.params.data_label} 
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey={this.state.params.data_value} fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>

        )
    }

}

export default GraficoBarra;