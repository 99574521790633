import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import cfg from '../utils/config';
import auth from '../utils/auth';
import ReactLoading from 'react-loading'

class ModalUltimoPacote extends Component {

    state = {
        modal: false,
        carregando: true,
        ultimo_pacote: ""
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    formataJSON = (json) => {
        let object = JSON.parse(json)
        let elements = []
        Object.keys(object).forEach(key => {
            elements.push(
                <div
                    key={key}
                    style={{ wordBreak: 'break-all', lineHeight: '16px' }}>
                    <p style={{ color: '#444', margin: '3px 0', }}>
                        {Array.isArray(object[key])
                            ? <> <b style={{ color: '#333' }}>{`${key}: `}</b>[{object[key].join(', ')}]</>
                            : <>  <b style={{ color: '#333' }}>{`${key}: `}</b>{object[key]}</>
                        }
                    </p>
                </div>)
        })
        return elements
    }

    buscar_data = () => {
        this.toggle()

        let userInfo = auth.getUserInfo();
        var bodyFormData = new FormData();

        bodyFormData.append('id_dispositivo', this.props.imei)

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/reporte-ultimo-evento/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then(response => {
                this.setState({ 'ultimo_pacote': JSON.stringify(response.data), carregando: false })
            })
            .catch((error) => console.log(error))
    }

    render = () => {
        const { ultimo_pacote } = this.state
        return (
            <div>
                <span style={{ cursor: "pointer", color: "#007bff" }} onClick={this.buscar_data}>{this.props.label}</span>
                <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Último reporte - {this.props.imei}</ModalHeader>
                    <ModalBody style={{ minHeight: '300px', padding: '0 20px', overflow: 'auto' }}>
                        {this.state.carregando
                            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                <ReactLoading
                                    type="spin"
                                    color="#589bd4"
                                    height={20}
                                    width={30}
                                />
                            </div> :
                            <div style={{ width: '100%', maxHeight: '300px', padding: '12px 0' }} >
                                {this.formataJSON(ultimo_pacote)}
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.toggle}>Sair</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ModalUltimoPacote