import React, { Component } from 'react';
let GoogleMapsLoader = require('google-maps');

GoogleMapsLoader.KEY = 'AIzaSyBHNL4tu1ZLvrXR_oBhyIHrlGWPlj5gtts';
GoogleMapsLoader.LANGUAGE = 'pt-br';
GoogleMapsLoader.REGION = 'BR';
GoogleMapsLoader.LIBRARIES = ['geometry', 'drawing'];
GoogleMapsLoader.VERSION = '3.37';

class Mapa extends Component {

    state = {
        google_object: false,
        map: false,
        loading: true,
    }

    componentWillMount = () => {
        GoogleMapsLoader.load((google) => {
            this.setState({ google_object: google })
        })
    }

    componentDidUpdate = () => {
        if (this.state.google_object && this.state.map === false) {
            this.createMap()
        } else if (this.state.google_object && this.state.map && this.state.loading) {
            this.setState({ loading: false })
            this.props.mapObjectFn({ map: this.state.map, google_map: this.state.google_object })
        }
    }

    createMap = () => {
        let map = new this.state.google_object.maps.Map(document.getElementById('map'), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 8,
            mapTypeId: 'roadmap',
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: this.state.google_object.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: this.state.google_object.maps.ControlPosition.BOTTOM_CENTER,
            },
            fullscreenControl: false,
        });

        /*let drawingManager = new this.state.google_object.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
                position: this.state.google_object.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['marker', 'circle', 'polygon', 'polyline', 'rectangle']
            },
            markerOptions: {icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'},
            circleOptions: {
                fillColor: '#ffff00',
                fillOpacity: 1,
                strokeWeight: 5,
                clickable: false,
                editable: true,
                zIndex: 1
            }
        })

        drawingManager.setMap(map)

        this.state.google_object.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
            console.log(event)
            if (event.type === 'circle') {
                let radius = event.overlay.getRadius()
                console.log(radius)
            }else if(event.type === 'polygon'){
                let area = this.state.google_object.maps.geometry.spherical.computeArea(event.overlay.getPath())
                console.log(area)
            }
        });*/

        map.setOptions({
            styles: [
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dedede"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                }
            ]
        })

        this.setState({ map: map })
    }

    componentWillReceiveProps(props) {
        if (props.filter) {
            this.state.map?.setCenter({ lat: Number(props.filter[0]), lng: Number(props.filter[1]) })
            this.state.map?.setZoom(20)
        }
    }

    createMarker = () => {
        let marker = new this.state.google_object.maps.Marker({
            position: { lat: -34.397, lng: 150.644 },
            title: "Hello World!"
        });
        let arrayMarkers = this.state.markers
        arrayMarkers.push(marker)
        marker.setMap(this.state.map)

        this.setState({ markers: arrayMarkers })
    }

    render() {
        return (
            <div id="map" style={{ boxShadow: '0' }}>

            </div>
        );
    }
}

export default Mapa;