import React, { Component } from 'react';
import { Switch } from 'react-router-dom'
import Grid from './grid'
import Form from './form'
import PrivateRoute from '../../containers/PrivateRoute'

class Setor extends Component {
	render() {
		return (
			<div className="hdv-render-div">
				<div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
					<Switch>
						<PrivateRoute exact path='/setor' component={Grid} />
						<PrivateRoute path='/setor/novo' component={Form} />
						<PrivateRoute path='/setor/:id/editar' component={Form} />
					</Switch>
				</div>
			</div>
		);
	}
}

export default Setor;
