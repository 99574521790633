import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import auth from './utils/auth';

 axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {

        if(error.response.status === 401){
            auth.clearAppStorage();
            window.location.reload();
        }

        return Promise.reject(error);
    });

ReactDOM.render((
    <BrowserRouter>
        <App />
    </BrowserRouter>
  ), document.getElementById('root'));


