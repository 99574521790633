import React from 'react'
import ReactTable from "react-table"
import auth from '../utils/auth'
import moment from 'moment'
import _ from 'underscore'
import config from '../utils/config'
import ModalAlerta from '../components/ModalAlerta'
import axios from 'axios'


export default class AlertaDashboard extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            loading: true,
            pages: 1,
            pageSize: 10,
        }
    }

    getAlertas = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                const userInfo = auth.getUserInfo()
                this.setState({ loading: true })

                axios({
                    method: 'GET',
                    url: config.base_api_url + config.api_version + '/' + userInfo.empresa + '/alerta-historico/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(state.filtered)),
                    headers: { 'Authorization': 'Bearer ' + auth.getToken() }
                })
                    .then((res) => {
                        if (res.data.results) {
                            this.setState({ data: res.data.results, pages: res.data.pages, loading: false })
                        } else
                            this.setState({ data: [], pages: res.data.pages, loading: false, })

                    })
            }
        }
    }, 500)


    render() {
        const columns = [{
            Header: 'Alerta',
            accessor: 'alerta__descricao',
            Cell: ({ original }) => {
                return (<ModalAlerta id_alerta={original.id} label={original.alerta__descricao} />)
            }
        }, {
            Header: 'Data do alerta',
            accessor: 'timestamp_gerado',
            Cell: ({ original }) => {
                let data_in_timezone_user = moment.tz(original.timestamp_gerado * 1000, 'UTC')
                let data_formatada = data_in_timezone_user.tz(auth.getUserInfo().timezone).format('DD/MM/YYYY HH:mm:ss')
                return (<span>{data_formatada}</span>)
            }
        }, {
            Header: 'Status',
            accessor: 'status',
        }, {
            Header: 'Severidade',
            accessor: 'alerta__severidade',
        }, {
            Header: 'Monitorado',
            accessor: 'alerta__monitorado__nome',
        }]

        return (
            <ReactTable
                ref={(refReactTable) => { this.refReactTable = refReactTable }}
                manual
                filterable
                data={this.state.data}
                pages={this.state.pages}
                loading={this.state.loading}
                onFetchData={this.getAlertas}
                columns={columns}
                defaultFiltered={[{ "id": "alerta__monitorado", "value": this.props.monitorado, "type": "int" }]}
                defaultPageSize={10}
                previousText={'Anterior'}
                nextText={'Próximo'}
                loadingText={'Carregando...'}
                noDataText={'Nenhum registro para exibir'}
                pageText={'Página'}
                ofText={'de'}
                rowsText={'linhas'}
            />
        )
    }
}