import { isEmpty } from 'lodash';

const TOKEN_KEY = '__hidroview__access_token';
const USER_INFO = '__hidroview__me';

const parse = JSON.parse;
const stringify = JSON.stringify;

const auth = {
    clear(key) {
        if (localStorage && localStorage.getItem(key)) {
            return localStorage.removeItem(key);
        }

        if (sessionStorage && sessionStorage.getItem(key)) {
            return sessionStorage.removeItem(key);
        }

        return null;
    },

    clearAppStorage() {
        if (localStorage) {
            localStorage.clear();
        }

        if (sessionStorage) {
            sessionStorage.clear();
        }
    },

    clearToken(tokenKey = TOKEN_KEY) {
        return auth.clear(tokenKey);
    },

    clearUserInfo(userInfo = USER_INFO) {
        return auth.clear(userInfo);
    },

    get(key) {
        if (localStorage && localStorage.getItem(key)) {
            return parse(localStorage.getItem(key)) || null;
        }

        if (sessionStorage && sessionStorage.getItem(key)) {
            return parse(sessionStorage.getItem(key)) || null;
        }

        return null;
    },

    getToken(tokenKey = TOKEN_KEY) {
        return auth.get(tokenKey);
    },

    getUserInfo(userInfo = USER_INFO) {
        return auth.get(userInfo);
    },

    set(value, key, isLocalStorage) {
        if (isEmpty(value)) {
            return null;
        }

        if (isLocalStorage && localStorage) {
            return localStorage.setItem(key, stringify(value));
        }

        if (sessionStorage) {
            return sessionStorage.setItem(key, stringify(value));
        }

        return null;
    },

    setToken(value = '', isLocalStorage = false, tokenKey = TOKEN_KEY) {
        return auth.set(value, tokenKey, isLocalStorage);
    },

    setUserInfo(value = '', isLocalStorage = false, userInfo = USER_INFO) {
        return auth.set(value, userInfo, isLocalStorage);
    },

    check_to_show(codigo_permissao_checar) {

        if (auth.getUserInfo()['tipo'] === "admin" || auth.getUserInfo()['tipo'] === "master"){
            return ""
        }

        let permissoes = auth.getUserInfo()['permissoes']
        
        let perm_checar = ""
        let tem_permissao = permissoes.filter((permissao) => {
            for (perm_checar in codigo_permissao_checar) {
                if(permissao.codigo_permissao === codigo_permissao_checar[perm_checar]){
                    return true
                }
            }
            return false
        })
        
        if (tem_permissao.length === 0){
            return "hdv-noshow-item"
        }

    },

};

export default auth;