import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import Select from 'react-select';
import auth from '../../utils/auth';
import cfg from '../../utils/config';

class Form extends Component {

    state = {
        detalhes: { "nome": "", "monitorado_id": "", "entrada_tipo": "", "equipamento_id": "", "entrada_id": [], 'cliente_id': "" },
        formerrors: [],
        redirect: false,
        path: "",
        monitorados: [],
        tipos: [{ value: "entrada_analogica", label: "Entradas Analógicas" }, { value: "entrada_digital", label: "Entradas Digitais" }],
        entradas: [{ value: "1", label: "Temperatura" }, { value: "2", label: "Sensor de Nível" }],
        equipamentos: [],
        clientes: [],
        optionSelected: { "monitorado_id": {}, "equipamento_id": {}, "entrada_tipo": {}, "cliente_id": {}, "entrada_id": {} },
        mapeamento: {},
        pacote: false,
        mapeamentoJson: {},
        array_entrada_selecionada: []
    }

    componentWillMount = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/', config_me))
        //requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item-tipo/', config_me))
        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/equipamento/', config_me))

        axios.all(requestArray)
            .then(axios.spread((clientes, equipamentos) => {
                let optionsClientes = []
                if (clientes.status === 200) {
                    clientes.data.forEach((a) => {
                        optionsClientes.push({ "value": a.id, "label": a.nome_fantasia })
                    })
                }
                /*let optionsTipos = []
                if(tipos.status === 200){
                    tipos.data.forEach((a) => {
                        optionsTipos.push({"value": a.id, "label": a.nome})
                    })
                }*/
                let optionsEquipamentos = []
                if (equipamentos.status === 200) {
                    equipamentos.data.forEach((a) => {
                        optionsEquipamentos.push({ "value": a.id, "label": a.imei })
                    })
                }
                this.setState({ "clientes": optionsClientes, 'equipamentos': optionsEquipamentos })
            }));
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let resource = "item"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                console.log(response)
                delete response.data.id

                let requestArray = []
                /*requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/item-tipo/' + response.data.tipo_id + '/', config_me))*/
                requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/buscar-entradas-hidrohub/' + response.data.equipamento__imei + '/', config_me))
                requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "cliente_id", "value": response.data.cliente_id, "type": "int" }])), config_me))

                let optionObj = this.state.optionSelected
                optionObj["equipamento_id"] = { "value": response.data.equipamento_id, "label": response.data.equipamento__imei }
                optionObj["entrada_tipo"] = { "value": response.data.entrada_tipo, "label": (response.data.entrada_tipo === this.state.tipos[0].value) ? "Entradas Analógicas" : "Entradas Digitais" }
                optionObj["monitorado_id"] = { "value": response.data.monitorado_id, "label": response.data.monitorado__nome }
                optionObj["cliente_id"] = { "value": response.data.cliente_id, "label": response.data.cliente__nome_fantasia }

                axios.all(requestArray)
                    .then(axios.spread((entradas_configuradas, monitorados) => {

                        let optionsMonitorados = []
                        if (monitorados.status === 200) {
                            monitorados.data.results.forEach((a) => {
                                optionsMonitorados.push({ "value": a.id, "label": a.nome })
                            })
                        }
                        console.log(response.data.entrada_tipo)
                        let arrayEntradaSelecionada = []
                        if (response.data.entrada_tipo === "entrada_analogica") {
                            entradas_configuradas.data.entrada4a20.forEach((value) => {
                                arrayEntradaSelecionada.push({ "value": value.id, "label": "#" + value.canal + " " + value.descricao })
                                if (value.id === response.data.entrada_id) {
                                    optionObj["entrada_id"] = { "value": response.data.entrada_id, "label": "#" + value.canal + " " + value.descricao }
                                }
                            })
                        } else if (response.data.entrada_tipo === "entrada_digital") {
                            entradas_configuradas.data.entradalogica.forEach((value) => {
                                arrayEntradaSelecionada.push({ "value": value.id, "label": "#" + value.canal + " " + value.tipo_entrada_logica__descricao })
                                if (value.id === response.data.entrada_id) {
                                    optionObj["entrada_id"] = { "value": response.data.entrada_id, "label": "#" + value.canal + " " + value.tipo_entrada_logica__descricao }
                                }
                            })
                        }



                        this.setState({ detalhes: response.data, monitorados: optionsMonitorados, entradas_configuradas: entradas_configuradas.data, optionSelected: optionObj, "array_entrada_selecionada": arrayEntradaSelecionada });
                    }));

            })
    }

    handleChange = (e) => {
        let obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        this.setState({ "detalhes": obj })
    }

    handleChangeSelect = (selectedOption, field) => {
        console.log(selectedOption, field)
        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        this.setState({ "optionSelected": optionObj, "detalhes": obj });
    }

    handleChangeSelectCliente = (selectedOption, field) => {

        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        obj['monitorado_id'] = ""

        let optionObj = this.state.optionSelected
        optionObj["monitorado_id"] = {}
        optionObj["cliente_id"] = { "value": selectedOption.value, "label": selectedOption.label }

        let resource = "monitorado"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "cliente_id", "value": selectedOption.value, "type": "int" }]));
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                let optionsMonitorados = []
                if (response.status === 200) {
                    response.data.results.forEach((a) => {
                        optionsMonitorados.push({ "value": a.id, "label": a.nome })
                    })
                }
                this.setState({ monitorados: optionsMonitorados, detalhes: obj, selectedOption: optionObj });
            })
    }

    handleChangeSelectTipo = (selectedOption, field) => {
        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        let arrayEntradaSelecionada = []

        if (selectedOption.value === "entrada_analogica") {
            this.state.entradas_configuradas.entrada4a20.forEach((value) => {
                arrayEntradaSelecionada.push({ "value": value.id, "label": "#" + value.canal + " " + value.descricao })
            })
        } else if (selectedOption.value === "entrada_digital") {
            this.state.entradas_configuradas.entradalogica.forEach((value) => {
                arrayEntradaSelecionada.push({ "value": value.id, "label": "#" + value.canal + " " + value.tipo_entrada_logica__descricao })
            })
        }

        this.setState({ "optionSelected": optionObj, "detalhes": obj, "array_entrada_selecionada": arrayEntradaSelecionada });

    }

    handleChangeSelectEquipamento = (selectedOption, field) => {
        console.log(selectedOption, field)
        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/buscar-entradas-hidrohub/' + selectedOption.label + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                this.setState({ "optionSelected": optionObj, "detalhes": obj, "entradas_configuradas": response.data });
            })

    }

    saveAction = (novo_cadastro) => {
        let userInfo = auth.getUserInfo();
        let resource = "item"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }
        let bodyFormData = new FormData();

        for (let key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key]);
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    if (this.props.match.params.id) {
                        this.setState({ redirect: true, path: "/item/novo" })
                    } else {
                        window.location.reload()
                    }
                } else {
                    this.setState({ redirect: true, path: "/item" })
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    generateOption = (source, value, desc) => {
        let options = source.map((obj, idx) =>
            <option key={idx} value={obj[value]}>{obj[desc]}</option>
        );
        return options;
    }

    handleChangeSelectMap = (selected, field) => {
        console.log(selected, field)
        let mapJson = this.state.mapeamentoJson
        let detalhes = this.state.detalhes
        mapJson[field] = selected
        console.log(mapJson)
        detalhes['mapeamento'] = JSON.stringify(mapJson)
        this.setState({ mapeamentoJson: mapJson, detalhes: detalhes })
    }

    generateSelectMapeamento = () => {
        let tipo_mapeamento = this.state.mapeamento.mapeamento
        let pacote = this.state.pacote
        if (tipo_mapeamento && pacote) {
            return tipo_mapeamento.map((v, i) => {
                return <div key={i} className="col-md-2">
                    <label className="required" htmlFor="mapeamento">Campo {v}</label>
                    <Select
                        value={(this.state.mapeamentoJson[v]) ? this.state.mapeamentoJson[v] : ""}
                        onChange={(e) => this.handleChangeSelectMap(e, v)}
                        options={this.state.pacote}
                    />
                </div>
            })
        } else {
            return ""
        }
    }

    render() {
        console.log(this.state)

        const selectedMonitorado = this.state.optionSelected.monitorado_id;
        const selectedEquipamento = this.state.optionSelected.equipamento_id;
        const selectedTipo = this.state.optionSelected.entrada_tipo;
        const selectedCliente = this.state.optionSelected.cliente_id;
        const selectedArrayEntradaSelecionada = this.state.optionSelected.entrada_id;

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <h4>Novo Item</h4>

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes do Item</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label className="required" htmlFor="monitorado">Descrição do Item</label>
                            <input type="text" value={this.state.detalhes.nome} onChange={this.handleChange} id="nome" name="nome" />
                            <label className="required" htmlFor="cliente_id">Cliente</label>
                            <Select
                                value={selectedCliente}
                                onChange={(e) => this.handleChangeSelectCliente(e, 'cliente_id')}
                                options={this.state.clientes}
                            />
                            <label className="required" htmlFor="monitorado">Monitorado</label>
                            <Select
                                value={selectedMonitorado}
                                onChange={(e) => this.handleChangeSelect(e, 'monitorado_id')}
                                options={this.state.monitorados}
                            />
                            <label className="required" htmlFor="equipamento">Equipamento</label>
                            <Select
                                value={selectedEquipamento}
                                onChange={(e) => this.handleChangeSelectEquipamento(e, 'equipamento_id')}
                                options={this.state.equipamentos}
                            />
                            <label className="required" htmlFor="tipo">Tipo Entrada</label>
                            <Select
                                value={selectedTipo}
                                onChange={(e) => this.handleChangeSelectTipo(e, 'entrada_tipo')}
                                options={this.state.tipos}
                            />
                            <label className="required" htmlFor="entrada_id">Entrada</label>
                            <Select
                                value={selectedArrayEntradaSelecionada}
                                onChange={(e) => this.handleChangeSelect(e, 'entrada_id')}
                                options={this.state.array_entrada_selecionada}
                            />
                        </form>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen">Salvar</button>
                    <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geenlight ">Salvar e Cadastrar um Novo</button>
                    <Link to="/item">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default Form;