import React, { Component } from 'react'
import PrivateRoute from '../PrivateRoute'
import { Switch } from 'react-router-dom'
import Grid from './grid'
import Form from './form'
import Relatorio from './relatorio'

export default class Sideccr extends Component {
    render() {
        return (
            <div className="hdv-render-div">
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <Switch>
                        <PrivateRoute exact path='/sideccr' component={Grid} />
                        <PrivateRoute path='/sideccr/novo' component={Form} />
                        <PrivateRoute path='/sideccr/:id/editar' component={Form} />
                        <PrivateRoute path='/sideccr/:id/relatorio' component={Relatorio} />
                    </Switch>
                </div>
            </div>
        )
    }
}