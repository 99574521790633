import  React, { Component } from 'react';
import helper_fn from '../../../utils/helper'

class ValorAtualV2 extends Component {

    state = {
        params: {
            descricao: "",
            comunicacao: false,
            value: 150,
            metric: "m",
            params: false,
            update: false,
            type_el: false,
        }
    }

    componentWillMount = () => {
        if(this.props.datawgt)
            this.setState({params: this.props.datawgt})
    }

    componentWillReceiveProps = (nextProps) => {
        if(this.props.datawgt.value !== nextProps.datawgt.value)
            this.setState({params: nextProps.datawgt})
    }

    render() {
        //console.log(this.state)
        let color_text = "black-txt"
        if (this.state.params.value.toFixed(2) >= parseFloat(this.state.params.params.valor_normal.min) && this.state.params.value.toFixed(2) < parseFloat(this.state.params.params.valor_normal.max)){
            color_text = "green-txt"
        }else if (this.state.params.value.toFixed(2) >= parseFloat(this.state.params.params.valor_alerta.min) && this.state.params.value.toFixed(2) < parseFloat(this.state.params.params.valor_alerta.max)){
            color_text = "yellow-txt"
        }else if (this.state.params.value.toFixed(2) >= parseFloat(this.state.params.params.valor_perigo.min) && this.state.params.value.toFixed(2) < parseFloat(this.state.params.params.valor_perigo.max)){
            color_text = "red-txt"
        }

        let value_show = parseFloat(this.state.params.value).toFixed(2)
        //console.log(this.state.params.type_el)
        if(this.state.params.type_el === 2){
            value_show = String(helper_fn.converte_decimal_to_hhmmss(this.state.params.value))
        }

        return (

                <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                    <div className="hdv-top-bar-bloco">
                        {(this.state.params.comunicacao === false)
                        ?
                        <i style={{color: "limegreen", zIndex: 999999, position: "relative"}} alt="Informação Atualizada"  title="Informação Atualizada" className="fa fa-square-full"></i>
                        :
                        <i style={{color: "tomato", zIndex: 999999, position: "relative"}} alt="Informação com Atraso"  title="Informação com Atraso" className="fa fa-square-full"></i>
                        }
                        {decodeURI(this.state.params.descricao)}
                    </div>
                    <div className="box-simple-value">
                        <div className="bar-value">
                            <div className="layout-inlineflex"><i className={"fa " + this.state.params.params.icone + " icon-big " + color_text}></i></div>
                            <div className="layout-inlineflex"><strong className={"value-super " + color_text}>{value_show}</strong></div>
                            <div className="hdv-metric-text">{this.state.params.metric}</div>
                        </div>
                        <div className="bar-info">
                            {
                                (this.state.params.params.valor_normal)
                                ?
                                <div className="layout-inlineflex bar-label bar-label-green"> {this.state.params.params.valor_normal.min} até {this.state.params.params.valor_normal.max} </div>
                                :
                                null
                            }
                            {
                                (this.state.params.params.valor_alerta)
                                ?
                                <div className="layout-inlineflex bar-label bar-label-yellow"> {this.state.params.params.valor_alerta.min} até {this.state.params.params.valor_alerta.max} </div>
                                :
                                null
                            }
                            {
                                (this.state.params.params.valor_perigo)
                                ?
                                <div className="layout-inlineflex bar-label bar-label-red"> {this.state.params.params.valor_perigo.min} até {this.state.params.params.valor_perigo.max} </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>

        )
    }

}

export default ValorAtualV2;