import React from 'react'
import ReactLoading from 'react-loading'

class CampoCustomizado extends React.Component {
    constructor() {
        super()
        this.state = {
            carregando: true,
            excecao: ['timestamp_dispositivo', 'timestamp_servidor', 'codigo_erro', 'versao', 'id_dispositivo', 'dia_sem', 'data_hora_dispositivo', 'codigo_produto'],
            campos: [],
            selected: ""
        }
    }

    componentWillReceiveProps(props) {
        let campos = []
        if (props.data) {
            this.setState({ carregando: true })

            campos = Object.entries(props.data).map(d => {
                if (!this.state.excecao.includes(d[0]))
                    return { label: d[0], value: d[1] }
                return undefined
            })
            campos = campos.filter(c => c !== undefined)
        }

        this.setState({ campos, carregando: false })
    }

    action = (campo, isArray, index) => {
        const { bracket } = this.props
        let selected
        if (isArray) {
            this.props.funcao(bracket ? `[${campo.label}_${index + 1}]` : `${campo.label}_${index + 1}`)
            selected = `${campo.label}_${index + 1}`
        } else {
            this.props.funcao(bracket ? `[${campo.label}]` : `${campo.label}`)
            selected = campo.label
        }

        this.setState({ selected })
    }

    render() {
        const { campos } = this.state

        if (this.state.carregando) {
            return (
                <div style={{ height: '200px', paddingLeft: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ReactLoading
                        className="hdv-report-loading"
                        type="spin"
                        color="#589bd4"
                        width={40}
                    />
                </div>
            )
        }

        return campos ? campos.map((campo, index) => {
            if (Array.isArray(campo.value)) {
                return (
                    <details key={index}>
                        <summary><b>{campo.label}</b></summary>
                        <div id="content">
                            {campo.value.map((valor, index) => {
                                const label = `${campo.label}_${index + 1}`
                                const selected = label === this.state.selected
                                valor = Number(valor).toFixed(2)

                                return (
                                    <div
                                        key={index}
                                        style={{ color: selected ? 'dodgerblue' : 'initial' }}
                                        onClick={() => this.action(campo, true, index)}>
                                        <small id="variavel">
                                            <b>[{label}]: </b> {valor}</small>
                                        <br />
                                    </div>
                                )
                            })}
                        </div>
                    </details>
                )
            } else {
                const selected = campo.label === this.state.selected

                return (
                    <details key={index}>
                        <summary><b>{campo.label}</b></summary>
                        <div
                            id="content"
                            style={{ color: selected ? 'dodgerblue' : 'initial' }}
                            onClick={() => this.action(campo, false, index)}>
                            <small id="variavel">
                                <b>[{`${campo.label}`}]:</b> {Number(campo.value).toFixed(2)}
                            </small>
                        </div>
                    </details>
                )
            }
        }) : null
    }
}


export default CampoCustomizado