import  React, { Component } from 'react';
import helper_fn from '../../../utils/helper'

class Status extends Component {

    state = {
        params: {
            descricao: "",
            comunicacao: false,
            value: 0,
            params: false,
        }
    }

    componentWillMount = () => {
        if(this.props.datawgt)
            this.setState({params: this.props.datawgt})
    }

    componentWillReceiveProps = (nextProps) => {
        if(this.props.datawgt.value !== nextProps.datawgt.value)
            this.setState({params: nextProps.datawgt})
    }

    render() {
        console.log(this.state)
        return (

                <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                    <div className="hdv-top-bar-bloco">
                        {(this.state.params.comunicacao === false) 
                        ? 
                        <i style={{color: "limegreen", zIndex: 999999, position: "relative"}} alt="Informação Atualizada"  title="Informação Atualizada" className="fa fa-square-full"></i>
                        :    
                        <i style={{color: "tomato", zIndex: 999999, position: "relative"}} alt="Informação com Atraso"  title="Informação com Atraso" className="fa fa-square-full"></i>
                        }
                        {decodeURI(this.state.params.descricao)}
                    </div>
                    <div className="box-simple-value">
                        <div className="bar-value">
                            <div className="layout-inlineflex"><strong className={"value-super"}>{(this.state?.params.value === 1) ? this.state.params.params?.on : this.state.params.params?.off}</strong></div>
                        </div>
                    </div>
                </div>

        )
    }

}

export default Status;