import  React, { Component } from 'react';

class Imagem extends Component {

    state = {
        params: {
            descricao: "",
            image: "url('https://www.plastico.com.br/wp-content/uploads/2014/12/Foto_Reci-Pocos__MG_reciclagem.jpg')",
            grid: { w: 2, h: 4, x: 0, y: 6, minW: 2, minH: 4 },
            id: "resr01"
        }
    }

    componentDidMount = () => {
        if(this.props.params)
            this.setState({params: this.props.params})
    }

    render() {
        return (

                <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                    <div className="hdv-top-bar-bloco">
                        {decodeURI(this.state.params.descricao)}
                    </div>
                    <div className="hdv-bullet-well">
                        <div className="hdv-image-widget" style={{"backgroundImage": this.state.params.image}}>
                        </div>
                    </div>
                </div>

        )
    }

}

export default Imagem;