import React, { Component } from 'react'
import ReactTable from "react-table"
import _ from "underscore"
import axios from 'axios'
import auth from '../../utils/auth'
import { Badge } from 'reactstrap'
import cfg from '../../utils/config'
import 'react-table/react-table.css'

class Acesso extends Component {
    constructor() {
        super()
        this.state = {
            selected: [],
            selectAll: 0,
            data: [],
            pages: null,
            loading: true,
            filtered: true,
            total: '',
            utilizados: '',
        }
    }

    toggleRow = (id) => {
        let selecionados = this.state.selected

        if (selecionados.includes(id)) {
            selecionados = selecionados.filter(item => item !== id)
        } else {
            selecionados.push(id)
        }

        this.setState({
            selected: selecionados,
            selectAll: 2
        })
    }

    toggleSelectAll = () => {
        let selecionados = []

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                selecionados.push(x.id)
            })
        }
        this.setState({
            selected: selecionados,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        })
    }

    getData = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true })
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
                let userInfo = auth.getUserInfo()

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/acesso/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(state.filtered)), config_me)
                    .then((response) => {

                        this.setState({
                            selected: [],
                            data: response.data.data,
                            loading: false,
                            pages: response.data.pages,
                            filtered: true,
                            total: response.data.total,
                            utilizados: response.data.utilizados,
                        })
                    })
            }
        }
    }, 500)

    render() {
        const columns = [
            {
                Header: 'Equipamento',
                accessor: 'equipamento__imei',
                Cell: ({ original }) => {
                    if (original.equipamento__imei) {
                        return <span>{original.equipamento__imei}</span>
                    } else {
                        return <span>-</span>
                    }
                }
            }, {
                Header: 'Data criado',
                accessor: 'data_criado',
                Cell: ({ original }) => {
                    let date = new Date(Date.parse(original.data_criado))
                    var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                    date = new Intl.DateTimeFormat('pt-Br', options).format(date)
                    return <span>{date}</span>
                }
            }, {
                Header: 'Data cancelado',
                accessor: 'data_cancelamento',
                Cell: ({ original }) => {
                    if (original.data_cancelamento) {
                        let date = new Date(Date.parse(original.data_cancelamento))
                        var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                        date = new Intl.DateTimeFormat('pt-Br', options).format(date)
                        return <span>{date}</span>
                    } else {
                        return <span>-</span>
                    }
                }
            }, {
                Header: 'Ativo',
                accessor: 'ativo',
                Cell: ({ original }) => {
                    return original.ativo ?
                        <div style={{ textAlign: "center" }}>
                            <Badge href="#" color="success">Sim</Badge>
                        </div>
                        :
                        <div style={{ textAlign: "center" }}>
                            <Badge href="#" color="secondary">Não</Badge>
                        </div>
                }
            }]

        return (
            <div className="hdv-render-div">
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <h4>Controle de Acessos</h4>
                    <div style={{ margin: '10px 0' }}>
                        <span>Acessos utilizados: {this.state.utilizados} / {this.state.total}</span><br />
                        <progress style={{ width: '160px' }} value={this.state.utilizados} max={this.state.total}></progress>
                    </div>

                    <ReactTable
                        ref={(refReactTable) => { this.refReactTable = refReactTable }}
                        manual
                        filterable
                        data={this.state.data}
                        pages={this.state.pages}
                        loading={this.state.loading}
                        onFetchData={this.getData}
                        columns={columns}
                        defaultPageSize={10}
                        previousText={'Anterior'}
                        nextText={'Próximo'}
                        loadingText={'Carregando...'}
                        noDataText={'Nenhum registro para exibir'}
                        pageText={'Página'}
                        ofText={'de'}
                        rowsText={'linhas'}
                    />
                </div>
            </div>
        )
    }
}

export default Acesso
