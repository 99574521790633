import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import Select from 'react-select';
import auth from '../../utils/auth';
import cfg from '../../utils/config';

class Form extends Component {

    state = {
        usuario_perfil: { "timezone": "", "email": "", "imagem_perfil": "", "senha": "" },
        formerrors: [],
        redirect: false,
        path: "",
        optionSelected: { "timezone": {} },
        timezone: [],
        imagem_perfil: "",
        configuracao_existe: false,
        confirma_senha: ""
    }

    componentDidMount = () => {
        this.getData()
    }

    getData = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/configuracao-timezone/', config_me))

        axios.all(requestArray)
            .then(axios.spread((timezones) => {
                let optionsTimezones = []
                let usuario_perfil_resp = { "timezone": userInfo['timezone'], "email": userInfo['email'], "imagem_perfil": "", "senha": "" }
                let imagem_perfil = ""
                let selectedOption = { "timezone": {} }

                if (timezones.status === 200) {
                    timezones.data.forEach((a) => {
                        optionsTimezones.push({ "value": a.codigo, "label": "(" + a.horas + ") " + a.codigo })
                    })
                }

                if (userInfo['timezone']) {
                    selectedOption.timezone = { "value": userInfo['timezone'], "label": userInfo['timezone'] }
                }

                imagem_perfil = userInfo['imagem_perfil']

                this.setState({ "timezone": optionsTimezones, usuario_perfil: usuario_perfil_resp, imagem_perfil: imagem_perfil, optionSelected: selectedOption })
            }));
    }

    handleChangeSistema = (e) => {
        var obj = this.state.usuario_perfil
        obj[e.target.id] = e.target.value
        this.setState({ "usuario_perfil": obj })
    }

    handleChangeLogomarca = (e) => {
        var obj = this.state.usuario_perfil
        obj[e.target.id] = e.target.files[0]
        if (e.target.files[0])
            this.setState({ "usuario_perfil": obj, imagem_perfil: URL.createObjectURL(e.target.files[0]) })
    }


    handleChangeSelect = (selectedOption, field) => {
        var obj = this.state.usuario_perfil
        obj[field] = selectedOption.value
        var optionObj = this.state.optionSelected
        optionObj[field] = selectedOption
        this.setState({ "optionSelected": optionObj, "usuario_perfil": obj });
    }

    saveAction = () => {
        let resource = "me"
        let requestURL = ''
        let verb = ''

        verb = 'patch'

        requestURL = cfg.base_api_url + cfg.api_version + '/' + resource + '/';

        var bodyFormData = new FormData();

        for (var key in this.state.usuario_perfil) {
            bodyFormData.append(key, this.state.usuario_perfil[key]);
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => window.location.reload())
            .catch((error) => console.log(error))
    }

    render() {
        const timezone = this.state.optionSelected.timezone;

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                <h4>Minha Conta</h4>

                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Meus Dados</Tab>
                        <Tab>Senha</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label htmlFor="timezone">Timezone</label>
                            <Select
                                value={timezone}
                                onChange={(e) => this.handleChangeSelect(e, 'timezone')}
                                options={this.state.timezone}
                            />
                            <label htmlFor="email">E-mail</label>
                            <input type="text" name="email" id="email" value={this.state.usuario_perfil.email} onChange={this.handleChangeSistema} />
                            <label htmlFor="imagem_perfil">Imagem de Perfil</label>
                            <input type="file" name="imagem_perfil" id="imagem_perfil" onChange={this.handleChangeLogomarca} />

                            <img className={(this.state.imagem_perfil) ? "hdv-logomarca-preview" : "hdv-noshow-item"} srcSet={this.state.imagem_perfil} alt="Perfil Preview" />
                        </form>
                    </TabPanel>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="senha">Nova Senha</label>
                                    <input className="hdv-form-system-password-field" type="password" name="senha" id="senha" value={this.state.usuario_perfil.senha} onChange={this.handleChangeSistema} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="confirma_senha">Confirmar Nova Senha</label>
                                    <input className="hdv-form-system-password-field" type="password" name="confirma_senha" id="confirma_senha" value={this.state.confirma_senha} onChange={(e) => this.setState({ confirma_senha: e.target.value })} />
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction()} className="hdv-btn-forms hdv-btn-geen">Salvar</button>
                </div>

            </div>
        );
    }
}

export default Form;