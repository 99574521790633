import React, { Component } from 'react'
import ReactTable from "react-table"
import { Link, Redirect } from "react-router-dom"
import _ from "underscore"
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import { Badge } from 'reactstrap'
import 'react-table/react-table.css'

class Grid extends Component {
    state = {
        selected: [],
        selectAll: 0,
        data: [],
        pages: null,
        loading: true,
        filtered: true,
        enableDeletar: false,
        enableEditar: false,
        enableRelatorio: false,
        redirectToEdit: false
    }

    toggleRow = (id) => {
        let selecionados = this.state.selected
        let editar = false
        let deletar = false
        let relatorio = false

        if (selecionados.includes(id)) {
            selecionados = selecionados.filter(item => item !== id)
        } else {
            selecionados.push(id)
        }

        if (selecionados.length === 1) {
            editar = true
            relatorio = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        this.setState({
            enableEditar: editar,
            enableDeletar: deletar,
            enableRelatorio: relatorio,
            selected: selecionados,
            selectAll: 2
        })
    }

    toggleSelectAll = () => {
        let selecionados = []
        let editar = false
        let deletar = false
        let relatorio = false

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                selecionados.push(x.id)
            })
        }

        if (selecionados.length === 1) {
            editar = true
            relatorio = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        this.setState({
            enableEditar: editar,
            enableDeletar: deletar,
            enableRelatorio: relatorio,
            selected: selecionados,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        })
    }

    getData = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true })
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
                let userInfo = auth.getUserInfo()

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/sideccr/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(state.filtered)), config_me)
                    .then((res) => {
                        this.setState({ selected: [], data: res.data.results, loading: false, pages: res.data.pages, filtered: true })
                    })
            }
        }
    }, 500)

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        this.setState({ loading: true, enableDeletar: false, enableEditar: false })

        this.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_version + `/${userInfo.empresa}/sideccr/${value}/`, config_me))
        )

        axios.all(requestArray).then(axios.spread((acct, perms) => this.refReactTable.fireFetchData()))
    }

    editar = () => {
        let toGo = "sideccr/" + this.state.selected[0] + "/editar"
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from })
    }

    relatorio = () => {
        let toGo = "sideccr/" + this.state.selected[0] + "/relatorio"
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from })
    }

    convertDate = (date) => new Date(Date.parse(date)).toLocaleDateString('pt-BR',
        { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    )

    render() {

        const { redirectToEdit } = this.state
        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />
        }

        const columns = [{
            Header: x => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.selectAll === 1}
                        ref={input => {
                            if (input) {
                                input.indeterminate = this.state.selectAll === 2
                            }
                        }}
                        onChange={() => this.toggleSelectAll()}
                    />
                )
            },
            sortable: false,
            width: 55,
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
                return (
                    <div className="hdv-aligncenter-checkboxgrid">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={(this.state.selected.includes(original.id) === true ? true : false)}
                            onChange={() => this.toggleRow(original.id)}
                        />
                    </div>
                )
            }
        }, {
            Header: 'Usuário',
            accessor: 'usuario'
        }, {
            Header: 'Última atualização',
            accessor: 'data_atualizado',
            filterable: false,
            Cell: ({ original }) => {
                return <span>{this.convertDate(original.data_atualizado)}</span>
            }
        }, {
            Header: 'Criado em',
            accessor: 'data_criado',
            filterable: false,
            Cell: ({ original }) => {
                return <span>{this.convertDate(original.data_criado)}</span>
            }
        }, {
            Header: 'Status',
            accessor: 'ativo',
            filterable: false,
            Cell: ({ original }) => {
                if (original.ativo) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Badge href="#" color="success">Ligado</Badge>
                        </div>
                    )
                } else {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Badge href="#" color="secondary">Desligado</Badge>
                        </div>
                    )
                }
            },
        }]

        return (
            <div>

                <h4>Cadastro de SIDECCR-R</h4>
                <div className="hdv-btn-group">
                    <Link to="/sideccr/novo">
                        <button className="hdv-btn-forms hdv-btn-geen ">Novo</button>
                    </Link>
                    <button onClick={this.state.enableEditar ? this.editar : null} className={"hdv-btn-forms hdv-btn-yellow " + (this.state.enableEditar ? "" : "hdv-btn-disable")}>Editar</button>
                    <button onClick={this.state.enableRelatorio ? this.relatorio : null} className={"hdv-btn-forms hdv-btn-blue " + (this.state.enableRelatorio ? "" : "hdv-btn-disable")}>Relatório</button>
                    <button onClick={this.state.enableDeletar ? this.deletar : null} className={"hdv-btn-forms hdv-btn-red " + (this.state.enableDeletar ? "" : "hdv-btn-disable")}>Excluir</button>
                </div>

                <ReactTable
                    ref={(refReactTable) => { this.refReactTable = refReactTable }}
                    manual
                    filterable
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    onFetchData={this.getData}
                    columns={columns}
                    defaultPageSize={10}
                    previousText={'Anterior'}
                    nextText={'Próximo'}
                    loadingText={'Carregando...'}
                    noDataText={'Nenhum registro para exibir'}
                    pageText={'Página'}
                    ofText={'de'}
                    rowsText={'linhas'}
                />

            </div >
        )
    }
}

export default Grid
