import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    Image
} from "@react-pdf/renderer";

export default function PdfDocument(props) {
    console.log(props)
    return (
        <Document>
            <Page size="A4" style={{paddingTop: 10,paddingBottom: 65,paddingHorizontal: 35}}>
                <View style={{display: "flex", flexDirection: "row", padding: 5}}>
                    {(props.data.detalhes_da_empresa.logomarca)
                    ?
                        <Image
                            style={{width: 150}}
                            src={props.data.detalhes_da_empresa.logomarca}
                        />
                    :   <Text>
                            &nbsp;
                        </Text>
                    }
                    <View style={{marginTop: 30, marginLeft: 110, backgroundColor: 'white', width: 250}}>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            {props.data.detalhes_da_empresa.descricao_horario_atendimento}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            {props.data.detalhes_da_empresa.endereco}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            Tel: {props.data.detalhes_da_empresa.telefone}
                        </Text>
                        <Text style={{fontSize: 10, marginBottom: 5, textAlign: 'right', color: 'grey'}} fixed>
                            E-mail: {props.data.detalhes_da_empresa.email}
                        </Text>
                    </View>
                </View>
                <View>
                    <Text style={{fontSize: 24, textAlign: 'center'}}>
                        Relatório de Dados Elétricos
                    </Text>
                </View>
                <View>
                    <Text style={{fontSize: 18, textAlign: 'center', marginBottom: 10, marginTop: 10}}>{props.data.descricao_chart}</Text>
                </View>
                <View style={{marginTop: 10}}>
                    <View>

                        {(props.data.charts.consumo_energia_image)
                        ?
                            <Image
                                style={{width: 510, height: 250}}
                                src={props.data.charts.consumo_energia_image}
                            />
                        :   <Text>
                                &nbsp;
                            </Text>
                        }

                    </View>
                </View>
                <View style={{display: "flex", flexDirection: "row"}}>
                    <View style={{width: 255}}>
                        <View>
                            <Text style={{fontSize: 18, textAlign: 'center', marginBottom: 10, marginTop: 10}}>Tensão</Text>
                        </View>
                        {(props.data.charts.energia_tensao_image)
                        ?
                            <Image
                                style={{width: 255, height: 200}}
                                src={props.data.charts.energia_tensao_image}
                            />
                        :   <Text>
                                &nbsp;
                            </Text>
                        }
                    </View>
                    <View style={{width: 255}}>
                        <View>
                            <Text style={{fontSize: 18, textAlign: 'center', marginBottom: 10, marginTop: 10}}>Corrente</Text>
                        </View>
                        {(props.data.charts.energia_corrente_image)
                        ?
                            <Image
                                style={{width: 255, height: 200}}
                                src={props.data.charts.energia_corrente_image}
                            />
                        :   <Text>
                                &nbsp;
                            </Text>
                        }
                    </View>
                </View>
            </Page>
        </Document>
    );
}