import  React, { Component } from 'react';
import LiquidFillGauge from 'react-liquid-gauge';

class Reservatorio extends Component {

    state = {
        gaugeRef: {h: 150},
        params: {
            descricao: "Reservatório",
            comunicacao: false,
            value: 100,
            id: "resr01",
            params: {}
        }
    }

    componentDidMount = () => {
        if(this.props.datawgt){
            let params = this.state.params
            params = this.props.datawgt
            //console.log(parseFloat(this.props.datawgt.params['valor_maximo']))
            params['value'] = (100 / parseFloat(this.props.datawgt.params['valor_maximo'])) * parseFloat(params['value'])

            this.setState({params: params})
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(this.props.datawgt.value !== nextProps.datawgt.value){
            let params = this.state.params
            params = nextProps.datawgt
            params['value'] = (100 / parseFloat(nextProps.datawgt.params['valor_maximo'])) * parseFloat(params['value'])

            this.setState({params: params})
        }
    }

    render() {
        //console.log(this.state)
        return (
                <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                    <div className="hdv-top-bar-bloco">
                        {(this.state.params.comunicacao === false) 
                        ? 
                        <i style={{color: "limegreen", zIndex: 999999, position: "relative"}} alt="Informação Atualizada"  title="Informação Atualizada" className="fa fa-square-full"></i>
                        :    
                        <i style={{color: "tomato", zIndex: 999999, position: "relative"}} alt="Informação com Atraso"  title="Informação com Atraso" className="fa fa-square-full"></i>
                        }
                        {decodeURI(this.state.params.descricao)}
                    </div>
                    <div className={"box-gauge id-reserv-" + this.state.params.id}>
                        <div className="box-gauge-margintop">
                            <LiquidFillGauge
                                style={{ margin: '0 auto' }}
                                width={(document.querySelector('.id-reserv-' + this.state.params.id)) ? document.querySelector('.id-reserv-' + this.state.params.id).offsetHeight / 2  : 150}
                                height={(document.querySelector('.id-reserv-' + this.state.params.id)) ? document.querySelector('.id-reserv-' + this.state.params.id).offsetHeight / 2 : 150}
                                value={this.state.params.value}
                                percent="%"
                                textSize={1}
                                textOffsetX={0}
                                textOffsetY={0}
                                textRenderer={(props) => {
                                    const value = Math.round(this.state.params.value);
                                    const radius = Math.min(props.height / 2, props.width / 2);
                                    const textPixels = (props.textSize * radius / 2);
                                    const valueStyle = {
                                        fontSize: textPixels
                                    };
                                    const percentStyle = {
                                        fontSize: textPixels * 0.6
                                    };

                                    return (
                                        <tspan>
                                            <tspan className="value" style={valueStyle}>{value}</tspan>
                                            <tspan style={percentStyle}>{props.percent}</tspan>
                                        </tspan>
                                    );
                                }}
                                riseAnimation
                                waveAnimation
                                waveFrequency={2}
                                waveAmplitude={1}
                            />
                        </div>
                    </div>
                </div>

        )
    }

}

export default Reservatorio;