import React from 'react'
import {
    InputGroup,
    InputGroupAddon,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Badge,
    ModalFooter
} from 'reactstrap'
import { DateRangePicker } from 'react-date-range'
import * as rdrLocales from 'react-date-range/dist/locale'
import Select from 'react-select'
import axios from 'axios'
import moment from 'moment'
import auth from '../../utils/auth'
import ReactLoading from 'react-loading'
import cfg from '../../utils/config'


export default class Logs extends React.Component {
    state = {
        logs: [],
        carregando_dados: false,
        dataFormatada: '',
        datePickerShow: false,
        dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
        detalhes: { data: '' },
        checkClose: false,
        tela: '',
        carregado: false,
        modal: false,
        drop: false,
        log: { valor_novo: '', valor_atual: '' },
        semResultado: false,
    }

    showDatePicker = () => {
        this.setState({ "datePickerShow": !this.state.datePickerShow })
    }

    checar_calendario_aberto = (e) => {
        if (this.state.checkClose === true) {
            let retArray = e.path.filter((path) => {
                if (path.className === "hdv-datepicker") {
                    return true
                }
                return false
            })

            if (retArray.length === 0) {
                this.setState({ "datePickerShow": false, checkClose: false })
            }
        } else
            this.setState({ checkClose: true })

        return true
    }

    manipular_data = (sentido) => {
        if (!this.state.detalhes.data)
            return

        let diffTime = Math.abs(this.state.dateRange.endDate.getTime() - this.state.dateRange.startDate.getTime());
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        diffDays = (diffDays === 0) ? 1 : diffDays
        diffDays = (sentido === "avancar") ? diffDays : -Math.abs(diffDays)

        let newStartDate = new Date(this.state.dateRange.startDate.setDate(this.state.dateRange.startDate.getDate() + diffDays))
        let newEndDate = new Date(this.state.dateRange.endDate.setDate(this.state.dateRange.endDate.getDate() + diffDays))

        let detalhes = this.state.detalhes
        detalhes.data = newStartDate.toLocaleDateString() + "-" + newEndDate.toLocaleDateString()

        this.setState({
            dateRange: { startDate: newStartDate, endDate: newEndDate, key: 'selection' },
            dataFormatada: newStartDate.toLocaleDateString() + " - " + newEndDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    handleInput = (name, e) => {
        this.setState({ [name]: e.value === 0 ? '' : e.label })
    }

    handleSelect = (ranges) => {
        let detalhes = this.state.detalhes
        detalhes.data = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
        this.setState({
            dateRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            },
            dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    handleSelect = (ranges) => {
        let data_inicio = ranges.selection.startDate
        let data_fim = ranges.selection.endDate
        if (ranges.selection.startDate === ranges.selection.endDate) {
            let data = new Date(ranges.selection.startDate.getTime())
            let dateArray = data.toLocaleDateString('pt-BR',
                {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                }
            ).split('/')

            data_inicio = new Date(dateArray[2], dateArray[1] - 1, dateArray[0], '00', '00', '00')
            data_fim = new Date(dateArray[2], dateArray[1] - 1, dateArray[0], '23', '59', '59')
        }

        const detalhes = this.state
        this.setState({
            dateRange: { startDate: data_inicio, endDate: data_fim, key: 'selection' },
            dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
            detalhes: detalhes,
            carregado: true
        })
    }

    filtrar = () => {
        this.setState({ carregando_dados: true })
        let { dateRange, tela } = this.state
        if (tela === 'Todas as telas') tela = ''
        const config = { headers: { 'Content-Type': 'application/json' } }
        let data = new FormData()
        data.append('data_inicio', (dateRange.startDate.getTime()) / 1000)
        data.append('data_fim', (dateRange.endDate.getTime()) / 1000)
        data.append('tela', tela ? tela : '')

        axios.post(cfg.base_api_url + cfg.api_version + `/${auth.getUserInfo().empresa}/log-acessos/`, data, config)
            .then(res => {
                if (!res.data)
                    this.setState({ semResultado: true, logs: res.data, carregando_dados: false })
                else {
                    let logs = res.data ?? []
                    logs.reverse()
                    this.setState({ logs, carregando_dados: false, semResultado: false })
                }
            })
            .catch(res => console.log(res))
    }

    convertToDate(stamp) {
        return moment(stamp * 1000).format('DD/MM H:mm:ss')
    }

    handleDescricao = (descricao) => {
        let desc = ''
        const icon = { fontSize: '18px' }
        if (descricao.includes('Cadastrou'))
            desc = (<><i style={icon} className="fa fa-plus"></i> {descricao}</>)
        else if (descricao.includes('Editou'))
            desc = (<><i style={icon} className="fa fa-pencil"></i> {descricao}</>)
        else if (descricao.includes('Deletou'))
            desc = (<><i style={icon} className="fa fa-trash"></i> {descricao}</>)
        else desc = descricao

        return desc
    }

    orderData = () => {
        let { logs } = this.state
        this.setState({ logs: logs.reverse() })
    }

    formataAlteracao = () => {
        let { log } = this.state
        let elements = []
        let diff = {}
        let novo = log.valor_novo
        let atual = log.valor_atual

        // if (Object.keys(novo).length >= Object.keys(atual).length) {
        //     Object.keys(novo).map(key => {
        //         if (!atual[key] || atual[key] != novo[key]) {
        //             diff[key] = novo[key]
        //         }
        //     })
        // } else {
        //     if (atual === "{}") return

        //     Object.keys(atual).map(key => {
        //         if (!novo[key] || novo[key] != atual[key]) {
        //             diff[key] = atual[key]
        //         }
        //     })
        // }

        if (Object.keys(novo).length >= Object.keys(atual).length) {
            Object.keys(novo).map(key => {
                if (!atual[key] || atual[key] != novo[key]) {
                    diff[key] = novo[key]
                }
            })
        } else {
            Object.keys(atual).map(key => {
                if (!novo[key] || novo[key] != atual[key]) {
                    diff[key] = atual[key]
                }
            })
        }

        if (atual !== "{}") {
            elements.push((<strong>Anterior</strong>))
            Object.keys(atual).forEach((key, index) => {
                elements.push(
                    <div
                        key={Math.random()}
                        style={{ wordBreak: 'break-all', lineHeight: '16px' }}>
                        <p style={{ color: '#444', margin: '0', opacity: '0.9' }}>
                            <b style={{ color: '#333' }}>{key}:</b> {String(atual[key])}
                        </p>
                    </div>
                )
            })
        }

        if (novo !== "{}") {
            elements.push((<><hr></hr><strong>Novo</strong></>))
            Object.keys(diff).forEach((key, index) => {
                elements.push(
                    <div
                        key={Math.random()}
                        style={{ wordBreak: 'break-all', lineHeight: '16px' }}>
                        <p style={{ color: '#444', margin: '0', opacity: '0.9' }}>
                            <b style={{ color: '#333' }}>{key}:</b> {String(diff[key])}
                        </p>
                    </div>
                )
            })
        }

        return elements
    }

    render() {
        const { carregado, logs, semResultado } = this.state

        const telas = [
            { value: 0, label: 'Todas' },
            { value: 1, label: 'aplicacao' },
            { value: 2, label: 'cliente' },
            { value: 3, label: 'empresa' },
            { value: 4, label: 'equipamento' },
            { value: 6, label: 'parametrizacao' },
            { value: 7, label: 'perfil-cliente' },
            { value: 8, label: 'perfil-usuario' },
            { value: 9, label: 'relatorio-consumo' },
            { value: 10, label: 'supervisorio' },
            { value: 11, label: 'usuario' },
        ]

        return (
            <div className="hdv-render-div">
                {this.state.modal ?
                    <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: !this.state.modal })}>
                        <ModalHeader
                            toggle={() => this.setState({ modal: !this.state.modal })}>
                            Alteração
                        </ModalHeader>
                        <ModalBody>
                            {this.formataAlteracao()}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="secondary"
                                onClick={() => this.setState({ modal: !this.state.modal })}>
                                Fechar
                            </Button>
                        </ModalFooter>
                    </Modal>
                    :
                    null}
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <h3 style={{ margin: '11px 0' }}>Logs de Acesso</h3>

                    <div className="row" style={{ marginTop: '25px' }}>
                        <div className="col-md-5">
                            <label className="required">Periodo</label>
                            <InputGroup className="hdv-force-hight">
                                <InputGroupAddon addonType="prepend">
                                    <Button onClick={() => this.manipular_data("voltar")} color="info">
                                        <i className="fa fa-angle-double-left"></i>
                                    </Button>
                                </InputGroupAddon>
                                <Input className="hdv-force-hight" placeholder="Selecione o periodo no botão 'Exibir Calendário' " onChange={() => false} disabled value={this.state.dataFormatada} />
                                <InputGroupAddon addonType="append">
                                    <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                        <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                        {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                    </Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType="append">
                                    <Button onClick={() => this.manipular_data("avancar")} color="info">
                                        <i className="fa fa-angle-double-right"></i>
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                            <div className={(this.state.datePickerShow) ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                <DateRangePicker
                                    locale={rdrLocales.pt}
                                    ranges={[this.state.dateRange]}
                                    onChange={this.handleSelect}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-4">
                            <label>Tela</label>
                            <Select
                                onChange={(e) => this.handleInput('tela', e)}
                                options={telas}
                            />
                        </div>
                    </div>

                    <button
                        disabled={!carregado}
                        style={{ margin: '20px 0' }}
                        onClick={() => this.filtrar()}
                        className={(carregado) ? "hdv-header-filters-button hdv-generate-report" : "hdv-header-filters-button hdv-generate-report hdv-block-button"}>
                        Gerar Relatório
                    </button>

                    <div style={{ margin: '6px 0' }}>
                        <ReactLoading
                            className={(this.state.carregando_dados) ? "hdv-report-loading" : "hdv-noshow-item"}
                            type="cylon"
                            color="#589bd4"
                            height={20}
                            width={50}
                        />
                    </div>
                    <br />
                    {!semResultado
                        ? <div style={{ maxHeight: '450px', overflowY: 'auto', width: '100%' }}>
                            <table className="table table-sm">
                                {logs.length > 0
                                    ?
                                    <thead>
                                        <tr>
                                            <th onClick={() => this.orderData()} style={{ cursor: 'pointer' }}>
                                                Data <i className="fa fa-sort"></i>
                                            </th>
                                            <th>Usuário</th>
                                            <th>Ação</th>
                                            <th>Tela</th>
                                            <th>Alteração</th>
                                        </tr>
                                    </thead>
                                    : null}
                                <tbody>
                                    {this.state.logs.map((log, index) => (
                                        <tr key={index}>
                                            <td>{this.convertToDate(log.timestamp)}</td>
                                            <td>{log.usuario}</td>
                                            <td>{this.handleDescricao(log.descricao)}</td>
                                            <td>{log.tela}</td>
                                            {log.valor_atual || log.valor_novo !== "{}"
                                                ? <td>
                                                    <Badge
                                                        style={{ padding: '5px 12px', cursor: 'pointer' }}
                                                        color="primary"
                                                        onClick={() => this.setState({ modal: true, log })}>
                                                        Ver
                                                    </Badge>
                                                </td> : <td>---</td>}
                                        </tr>))}
                                </tbody>
                            </table>
                        </div>
                        : <p>Não houve resultados</p>}
                </div>
            </div>
        )
    }
}