import React, { Component } from 'react';
import { Switch } from 'react-router-dom'
import Form from './form'
import PrivateRoute from '../PrivateRoute'

class CampoCustomizado extends Component {
    render() {
        return (
            <div className="hdv-render-div">
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">

                    <Switch>
                        <PrivateRoute path='/campo-customizado/:imei/novo' component={Form} />
                        <PrivateRoute path='/campo-customizado/:imei/editar/:id' component={Form} />
                    </Switch>

                </div>
            </div>
        )
    }
}

export default CampoCustomizado
