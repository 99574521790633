import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom'
import "react-tabs/style/react-tabs.css";
import axios from 'axios'
import Select from 'react-select';
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import Aviso from '../../components/Aviso'

class Form extends Component {

    state = {
        detalhes: { "nome": "", "aplicacao_id": "", "descricao": "", "cliente_id": "" },
        formerrors: [],
        redirect: false,
        path: "",
        aplicacoes: [],
        selectedOption: { cliente_id: null, aplicacao_id: null },
        clientes: [],
        aviso: { show: false, args: null, message: '' },
        cooldown: false,
    }

    componentWillMount = () => {
        let resource = "cliente"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                console.log(response.data)
                let optionsClientes = []
                if (response.status === 200) {
                    response.data.forEach((a) => {
                        optionsClientes.push({ "value": a.id, "label": a.nome_fantasia })
                    })
                }
                this.setState({ clientes: optionsClientes });
            })
    }

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.getData()
        }
    }

    getData = () => {
        let resource = "setor"
        let userInfo = auth.getUserInfo();
        let requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response_id) => {

                delete response_id.data.id

                resource = "aplicacao"
                requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "cliente_id", "value": response_id.data.cliente_id, "type": "int" }]))

                axios.get(requestURL, config_me)
                    .then((response) => {
                        let optionsAplicacoes = []
                        if (response.status === 200) {
                            response.data.results.forEach((a) => {
                                optionsAplicacoes.push({ "value": a.id, "label": a.nome })
                            })
                        }
                        this.setState({
                            detalhes: response_id.data,
                            selectedOption: {
                                "aplicacao_id": { "value": response_id.data.aplicacao_id, "label": response_id.data.aplicacao__nome },
                                "cliente_id": { "value": response_id.data.cliente_id, "label": response_id.data.cliente__nome_fantasia }
                            },
                            aplicacoes: optionsAplicacoes,
                        });
                    })
            })
    }

    handleChange = (e) => {
        let obj = this.state.detalhes
        obj[e.target.id] = e.target.value
        document.querySelector(`#validacao-${e.target.name}`).innerHTML = ''
        this.setState({ "detalhes": obj })
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    handleChangeSelectCliente = (selectedOption, field) => {

        let obj = this.state.detalhes
        obj[field] = selectedOption.value
        obj['aplicacao_id'] = ""

        let resource = "aplicacao"
        let userInfo = auth.getUserInfo();
        const requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/?page=0&pageSize=10&filter=' + encodeURI(JSON.stringify([{ "id": "cliente_id", "value": selectedOption.value, "type": "int" }]));
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        axios.get(requestURL, config_me)
            .then((response) => {
                let optionsAplicacoes = []
                if (response.status === 200) {
                    response.data.results.forEach((a) => {
                        optionsAplicacoes.push({ "value": a.id, "label": a.nome })
                    })
                }
                this.setState({ aplicacoes: optionsAplicacoes, detalhes: obj, selectedOption: { cliente_id: { label: selectedOption.label, value: selectedOption.value }, aplicacao_id: null } });
            })
        document.querySelector(`#validacao-cliente`).innerHTML = ''
    }

    handleChangeSelect = (selectedOption, field) => {
        console.log(selectedOption, field)
        let obj = this.state.detalhes
        let selecteds = this.state.selectedOption
        obj[field] = selectedOption.value
        selecteds[field] = selectedOption
        if (field === "aplicacao_id")
            document.querySelector('#validacao-aplicacao').innerHTML = ''
        this.setState({ selectedOption: selecteds, "detalhes": obj });
    }

    saveAction = (novo_cadastro) => {
        let userInfo = auth.getUserInfo();
        let resource = "setor"
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/' + this.props.match.params.id + '/';
            verb = 'patch'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/' + resource + '/';
            verb = 'post'
        }
        let bodyFormData = new FormData();

        for (let key in this.state.detalhes) {
            bodyFormData.append(key, this.state.detalhes[key]);
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                if (novo_cadastro === true) {
                    if (this.props.match.params.id) {
                        const aviso = { show: true, message: `Setor ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`, args: { redirect: true, path: "/setor/novo" } }
                        this.setState({ aviso })
                    } else {
                        window.location.reload()
                    }
                } else {
                    const aviso = { show: true, message: `Setor ${verb === 'patch' ? 'editado' : 'criado'} com sucesso`, args: { redirect: true, path: "/setor" } }
                    this.setState({ aviso })
                }
            })
            .catch((error) => {
                console.log(error)
                let erros = error.response.data
                if (Array.isArray(erros)) {
                    for (let i = 0; i < erros.length; i++) {
                        let span = document.querySelector(`#validacao-${erros[i][0]}`)
                        if (span)
                            span.innerHTML = erros[i][1]
                    }
                }
            })
        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    generateOption = (source, value, desc) => {
        let options = source.map((obj, idx) =>
            <option key={idx} value={obj[value]}>{obj[desc]}</option>
        );
        return options;
    }

    render() {
        console.log(this.state)
        const { cliente_id, aplicacao_id } = this.state.selectedOption;

        if (this.state.redirect === true) {
            return <Redirect to={this.state.path} />
        }

        return (
            <div>
                {this.props.match.params.id ? <h4>Editar Setor</h4> : <h4>Novo Setor</h4>}
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />
                <Tabs className="tabs-style">
                    <TabList>
                        <Tab>Detalhes do Setor</Tab>
                    </TabList>

                    <TabPanel>
                        <form className="hdv-form-default">
                            <label className="required" htmlFor="cliente_id">Cliente</label>
                            <Select
                                name={"cliente_id"}
                                id={"cliente_id"}
                                value={cliente_id}
                                onChange={(e) => this.handleChangeSelectCliente(e, 'cliente_id')}
                                options={this.state.clientes}
                            />
                            <span id="validacao-cliente"></span>
                            <label className="required" htmlFor="aplicacao">Aplicação</label>
                            <Select
                                name={"aplicacao_id"}
                                id={"aplicacao_id"}
                                value={aplicacao_id}
                                onChange={(e) => this.handleChangeSelect(e, 'aplicacao_id')}
                                options={this.state.aplicacoes}
                            />
                            <span id="validacao-aplicacao"></span>
                            <label className="required" htmlFor="nome">Nome do Setor</label>
                            <input type="text" name="nome" id="nome" value={this.state.detalhes.nome} onChange={this.handleChange} />
                            <span id="validacao-nome"></span>
                            <label htmlFor="Descrição">Descrição</label>
                            <input type="text" name="descricao" id="descricao" value={this.state.detalhes.descricao} onChange={this.handleChange} />
                            <span id="validacao-descricao"></span>
                        </form>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button>
                    <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geenlight" disabled={this.state.cooldown}>Salvar e Cadastrar um Novo</button>
                    <Link to="/setor">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default Form;