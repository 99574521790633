import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class RedirectRoute extends Component {
    render = () => {
        return <Redirect
            to={{
                pathname: this.props.location.state.pathto
            }}
        />
    }
}

export default RedirectRoute;