import React, { Component } from 'react';
import './style.css'

class Bar extends Component {

    render() {
        let barClass = 'bar ' + this.props.color;
        let width = this.props.width + '%';
        return (
            <div className={barClass} style={{left: '0px', width: width}} />
        );
    }
};

class Marker extends Component {

    render() {
        let markerClass = 'marker ' + this.props.color;
        let position = this.props.position + '%';
        return (
            <div className={markerClass} style={{left: position}} />
        );
    }
};

class Tick extends Component {

    render() {
        let position = this.props.position + '%';
        return (
            <div>
                <div className="tick" style={{left: position}}></div>
                <div className="tick-label" style={{left: position}}>{position}</div>
            </div>
        );
    }
};

class TickBar extends Component {

    render() {
        let ticks = this.props.ticks.map((val, key) => {
            return <Tick key={key} position={val} />;
        });
        return (
            <div className="tick-bar">
                {ticks}
            </div>
        );
    }
};

class LegendItem extends Component {

    render() {
        let legendClass = ['legend-symbol', this.props.type, this.props.color].join(' ');
        return (
            <div className="legend-item">
                <span className={legendClass}></span>
                <span className="legend-label">{this.props.label}</span>
            </div>
        );
    }
}

class Legend extends Component {

    state = {
        size: 100,
        bars: [],
        markers: []
    }

    render() {
        let markerItems = this.props.markers.map((item, key) => {
            return <LegendItem key={key} type="marker" label={item.title} color={item.css} />;
        });
        let barItems = this.props.bars.map((item, key) => {
            return <LegendItem key={key} type="bar" label={item.title} color={item.css} />;
        });
        let size = this.props.size + '%';
        return (
            <div className="legend" style={{width: size}}>
                {markerItems}
                {barItems}
            </div>
        );
    }
};

class BulletChart extends Component {

    state = {
        size: 100,
        bars: [
            { title: 'Posicao normal', value: 75, css: '' },
            { title: 'Posicao Atual', value: 50, css: 'blue' }
        ],
        markers: [
            { title: 'Área esperada', value: 80, css: 'green' },
            { title: 'Zona de Perigo', value: 40, css: 'red' }
        ],
        ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        showLegend: true
    }

    render() {
        let bars = this.props.data.bars.map((bar, key) => {
            return <Bar key={key} width={bar.value} color={bar.css} />;
        });
        let markers = this.props.data.markers.map((marker, key) => {
            return <Marker key={key} position={marker.value} color={marker.css} />;
        });
        let size = this.props.data.size + '%';
        return (
            <div>
                <div className="chart bullet-chart">
                    <div className="chart-container" style={{width: size}}>
                        <TickBar ticks={this.props.data.ticks} />
                        {bars}
                        {markers}
                    </div>
                </div>
                <div className="chart bullet-chart margin-top-legend">
                    {this.props.data.showLegend ? <Legend bars={this.props.data.bars} markers={this.props.data.markers} size={this.props.data.size} /> : null}
                </div>
            </div>
        );
    }

}

export default BulletChart;