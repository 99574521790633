import  React, { Component } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';
import moment from 'moment';

class GraficoLinha extends Component {

    state = {
        params: {
            descricao: "",
            value: [],
            data_value: "",
            data_label: "",
            params: {valor_minimo: false, valor_maximo: false}
        }
    }

    componentDidMount = () => {
        if(this.props.datawgt){
            let params_select = this.state.params
            console.log(params_select)
            console.log(this.props.datawgt)
            params_select['descricao'] = this.props.datawgt['descricao']
            if(this.props.datawgt['value'].name !== undefined)
                params_select['value'].push(this.props.datawgt['value'])
            params_select['data_value'] = this.props.datawgt['data_value']
            params_select['data_label'] = this.props.datawgt['data_label']
            params_select['params'] = this.props.datawgt['params']
            this.setState({params: params_select})
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(this.props.datawgt.value !== nextProps.datawgt.value){
            let params_select = this.state.params
            console.log(params_select)
            console.log(this.props.datawgt)
            params_select['descricao'] = this.props.datawgt['descricao']
            if(this.props.datawgt['value'].name !== undefined && params_select['value'].filter( (e) => {return e.name === this.props.datawgt['value'].name}).length === 0)
                params_select['value'].push(this.props.datawgt['value'])
            params_select['data_value'] = this.props.datawgt['data_value']
            params_select['data_label'] = this.props.datawgt['data_label']
            params_select['params'] = this.props.datawgt['params']
            if (params_select['value'].length > 180)
                params_select['value'].shift()
            this.setState({params: params_select})
        }
    }

    render() {
        console.log(this.state)
        return (

                <div className="hdv-bloco-informacao hdv-chart-bloco-reactgridlayout">
                    <div className="hdv-top-bar-bloco">
                        {(this.state.params.comunicacao === false) 
                        ? 
                        <i style={{color: "limegreen", zIndex: 999999, position: "relative"}} alt="Informação Atualizada"  title="Informação Atualizada" className="fa fa-square-full"></i>
                        :    
                        <i style={{color: "tomato", zIndex: 999999, position: "relative"}} alt="Informação com Atraso"  title="Informação com Atraso" className="fa fa-square-full"></i>
                        }
                        {decodeURI(this.state.params.descricao)}
                    </div>
                    <span>{console.log(this.state.params.value)}</span>
                    <ResponsiveContainer width="100%">
                        <LineChart key={this.state.params.value[this.state.params.value.length-1]?.name}  data={this.state.params.value} margin={{top: 20, right: 50, left: 20, bottom: 50}}>
                            <Line type="monotone" dataKey={this.state.params.data_value} stroke="#8884d8" />
                            <CartesianGrid stroke="#ccc" />
                            <XAxis 
                                dataKey={this.state.params.data_label} 
                                tickFormatter={timeStr => moment(timeStr).format('HH:mm')}
                                
                                 />
                            <YAxis type="number" domain={['auto', 'auto']} />
                            {

                                (this.state.params.params['valor_minimo']) ?
                                    <ReferenceLine y={this.state.params.params['valor_minimo']} label="Valor Mínimo" stroke="red"/>
                                : null

                            }

                            {

                                (this.state.params.params['valor_maximo']) ?
                                <ReferenceLine y={this.state.params.params['valor_maximo']} label="Valor Máximo" stroke="red"/>
                                : null

                            }
                        </LineChart>
                    </ResponsiveContainer>
                </div>

        )
    }

}

export default GraficoLinha;