import React from 'react'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import axios from 'axios'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import Aviso from '../../components/Aviso'
import { Redirect } from 'react-router-dom'

export default class Form extends React.Component {
    constructor() {
        super()
        this.state = {
            optionSelected: { cliente: null, monitorado: null, item: null, canal: null },
            sideccr: { usuario: "", codigo: "", chave: "", enviarDados: false, entrada_digital: "", entrada_analogica: "", dispositivo: "", tipo: "digital" },
            validacao: { data: true, cliente: true, monitorado: true, item: true, canal: true },
            validacaoSideccr: { usuario: true, codigo: true, chave: true },
            clientes: [],
            monitorados: [],
            itens: [],
            canais: [],
            aviso: { show: false, args: null, message: '' },
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getData()
        } else {
            let requestArray = []
            let userInfo = auth.getUserInfo()
            let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
            requestArray.push(axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/cliente/', config_me))

            axios.all(requestArray)
                .then(axios.spread((cliente) => {
                    let optionsCliente = []
                    if (cliente.data.length > 0) {
                        cliente.data.forEach((c) => {
                            optionsCliente.push({ "value": c.id, "label": c.nome_fantasia + ' - ' + c.cpf_cnpj })
                        })
                    }
                    this.setState({ "clientes": optionsCliente })
                }))
        }
    }

    getData() {
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

        axios.get(cfg.base_api_url + cfg.api_version + `/${userInfo.empresa}/sideccr/${this.props.match.params.id}`, config_me)
            .then(res => {
                console.log(res.data)
                let { sideccr } = this.state

                sideccr['chave'] = res.data.sideccr.chave
                sideccr['usuario'] = res.data.sideccr.usuario
                sideccr['enviarDados'] = res.data.sideccr.ativo
                sideccr['codigo'] = res.data.sideccr.codigo_medidor
                sideccr['tipo'] = res.data.sideccr.tipo
                sideccr['entrada_digital'] = res.data.sideccr.id_entrada_digital_id
                sideccr['entrada_analogica'] = res.data.sideccr.id_entrada_analogica_id
                sideccr['dispositivo'] = {
                    'label': res.data.sideccr.id_dispositivo__imei,
                    'value': res.data.sideccr.id_dispositivo_id
                }

                this.setState({ sideccr: sideccr })
            })
    }

    saveAction = (novoCadastro) => {
        this.setState({ cooldown: true })
        const { optionSelected, validacao, validacaoSideccr, sideccr } = this.state

        let notValidate = false
        let validateForm = validacao
        if (!this.props.match.params.id) {
            for (let key in optionSelected) {
                if (!optionSelected[key]) {
                    validateForm[key] = false
                    notValidate = true
                } else
                    validateForm[key] = true
            }

            if (notValidate) {
                this.setState({ validacao: validateForm })
                setTimeout(() => this.setState({ cooldown: false }), 2500)
                return
            }
        }

        notValidate = false
        validateForm = validacaoSideccr

        for (let key in validacaoSideccr) {
            if (!sideccr[key]) {
                validateForm[key] = false
                notValidate = true
            } else
                validateForm[key] = true
        }

        if (notValidate) {
            this.setState({ validacaoSideccr: validateForm })
            setTimeout(() => this.setState({ cooldown: false }), 2500)
            return
        }

        let userInfo = auth.getUserInfo()
        let requestURL = ''
        let verb = ''
        if (this.props.match.params.id) {
            requestURL = cfg.base_api_url + cfg.api_version + `/${userInfo.empresa}/sideccr/` + this.props.match.params.id + '/'
            verb = 'PATCH'
        } else {
            requestURL = cfg.base_api_url + cfg.api_version + `/${userInfo.empresa}/sideccr/`
            verb = 'POST'
        }

        let bodyFormData = new FormData()

        bodyFormData.append('usuario', sideccr.usuario)
        bodyFormData.append('codigo_medidor', sideccr.codigo)
        bodyFormData.append('chave', sideccr.chave)
        bodyFormData.append('ativo', sideccr.enviarDados ? '1' : '0')

        if (this.props.match.params.id) {
            bodyFormData.append('id_dispositivo_id', sideccr.dispositivo.value)
            bodyFormData.append('tipo', sideccr.tipo)
            if (sideccr.tipo == "digital") {
                bodyFormData.append('id_entrada_digital_id', sideccr.entrada_digital)
            } else {
                bodyFormData.append('id_entrada_analogica_id', sideccr.entrada_analogica)
            }
        } else {
            bodyFormData.append('id_dispositivo_id', optionSelected.item.value)
            bodyFormData.append('tipo', optionSelected.canal.type)
            if (optionSelected.canal.type == "digital") {
                bodyFormData.append('id_entrada_digital_id', optionSelected.canal.value)
            } else {
                bodyFormData.append('id_entrada_analogica_id', optionSelected.canal.value)
            }
        }

        axios({
            method: verb,
            url: requestURL,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        }).then(res => {
            let aviso
            if (novoCadastro)
                aviso = { show: true, message: `${verb === "POST" ? 'Criado' : 'Editado'} com sucesso`, args: { redirect: true, path: `/sideccr/novo/` } }
            else
                aviso = { show: true, message: `${verb === "POST" ? 'Editado' : 'Editado'} com sucesso`, args: { redirect: true, path: "/sideccr" } }

            this.setState({ aviso })
        })
        setTimeout(() => this.setState({ cooldown: false }), 2500)
    }

    handleChangeCliente = (selectedOption, field) => {
        let obj = this.state.optionSelected
        obj[field] = selectedOption.value
        obj['monitorado'] = obj['canal'] = obj['item'] = null
        obj['cliente_nome'] = selectedOption.label
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let userInfo = auth.getUserInfo()
        let bodyFormData = new FormData()

        bodyFormData.append('cliente_id', selectedOption.value)

        axios({
            method: 'POST',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado/filtro/',
            data: bodyFormData,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                let optionsMonitorado = []
                response.data.forEach((c) => {
                    optionsMonitorado.push({ "value": c.id, "label": c.nome })
                })
                this.setState({ "monitorados": optionsMonitorado, "optionSelected": optionObj })
            })
            .catch((error) => console.log(error))
    }

    toggleAviso = () => {
        const { aviso } = this.state
        aviso['show'] = !aviso['show']
        this.setState({ aviso })
    }

    buscaCanais4a20 = () => {
        if (this.state.optionSelected.item != null) {
            let userInfo = auth.getUserInfo()
            let bodyFormData = new FormData()
            bodyFormData.append('hidrohub__imei', this.state.optionSelected.item.value)

            axios({
                method: 'POST',
                url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/parametrizacao/filtro/',
                data: bodyFormData,
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
            })
                .then((response) => {

                    let canais = []

                    let canais_logicos = []
                    console.log(response)
                    response.data.entradalogica.forEach((c) => {
                        canais_logicos.push({ "value": c.id, "label": "#" + c.canal + " - " + c.descricao, "type": "digital" })
                    })

                    canais.push({ label: "Canais Digitais", options: canais_logicos })

                    let canais_analogicos = []
                    console.log(response)
                    response.data.entrada4a20.forEach((c) => {
                        canais_analogicos.push({ "value": c.id, "label": "#" + c.canal + " - " + c.descricao, "type": "analogica" })
                    })

                    canais.push({ label: "Canais Analógicos", options: canais_analogicos })

                    this.setState({ "canais": canais, configuracaoSelecionada: response.data })
                })
                .catch(error => console.log(error))
        }
    }

    handleChangeMonitorado = (selectedOption, field) => {
        let obj = this.state.optionSelected
        obj[field] = selectedOption.value
        obj['item'] = obj['canal'] = null
        let optionObj = this.state.optionSelected
        optionObj[field] = selectedOption

        let userInfo = auth.getUserInfo()

        axios({
            method: 'GET',
            url: cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/monitorado-equipamento/' + selectedOption.value + '/',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
        })
            .then((response) => {
                let optionsItem = []
                response.data.forEach((c) => optionsItem.push({ "value": c, "label": c }))
                this.setState({ "itens": optionsItem, "optionSelected": optionObj })
            })
            .catch((error) => console.log(error))
    }

    handleChangeItem = (selectedOption, field) => {
        let optionObj = this.state.optionSelected
        optionObj['canal'] = null
        optionObj[field] = selectedOption
        this.setState({ "optionSelected": optionObj })
        this.buscaCanais4a20()
    }

    handleCheckbox = (e) => {
        let { sideccr } = this.state
        sideccr['enviarDados'] = !sideccr['enviarDados']
        this.setState({ sideccr })
    }

    handleChangeCanal = (selectedOption, field) => {
        let obj = this.state.optionSelected
        obj[field] = selectedOption
        this.setState({ optionSelected: obj })
        return null
    }

    handleChangeSideccr = (e) => {
        let { sideccr } = this.state
        sideccr[e.target.name] = e.target.value
        this.setState({ sideccr })
    }

    render() {
        const { validacao, sideccr, validacaoSideccr } = this.state
        const clienteOption = this.state.optionSelected.cliente
        const monitoradoOption = this.state.optionSelected.monitorado
        const itemOption = this.state.optionSelected.item
        const canalOption = this.state.optionSelected.canal

        if (this.state.redirect === true) {
            return <Redirect to={{ pathname: '/redirect', state: { pathto: this.state.path } }} />
        }
        
        return (
            <div>
                <Aviso
                    toggle={this.toggleAviso}
                    mensagem={this.state.aviso.message}
                    action={() => this.setState(this.state.aviso.args)}
                    open={this.state.aviso.show}
                />
                {this.props.match.params.id ? <h4>Editar SIDECCR-R</h4> : <h4>Novo SIDECC-R</h4>}
                <form className="hdv-form-default row">
                    {!this.props.match.params.id ? <>

                        <div className="col-md-3">
                            <label className="required">Cliente</label>
                            <Select
                                value={clienteOption}
                                name="cliente"
                                onChange={(e) => this.handleChangeCliente(e, 'cliente')}
                                options={this.state.clientes}
                            />
                            <span className={!validacao.cliente ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                        </div>
                        <div className="col-md-3">
                            <label className="required">Monitorado</label>
                            <Select
                                value={monitoradoOption}
                                name="monitorado"
                                onChange={(e) => this.handleChangeMonitorado(e, 'monitorado')}
                                options={this.state.monitorados}
                            />
                            <span className={!validacao.monitorado ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                        </div>
                        <div className="col-md-3">
                            <label className="required">Equipamento</label>
                            <Select
                                value={itemOption}
                                onChange={(e) => this.handleChangeItem(e, 'item')}
                                options={this.state.itens}
                            />
                            <span className={!validacao.item ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                        </div>
                        <div className="col-md-3">
                            <label className="required">Canal</label>
                            <Select
                                value={canalOption}
                                onChange={(e) => { this.handleChangeCanal(e, 'canal') }}
                                options={this.state.canais}
                            />
                            <span className={!validacao.canal ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                        </div>
                    </> : null}
                    <div className="col-md-6">
                        <label className="required">Usuário
                            <input
                                type="text"
                                value={sideccr.usuario}
                                name="usuario"
                                onChange={(e) => this.handleChangeSideccr(e)}
                            />
                            <span className={!validacaoSideccr.usuario ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                        </label>

                        <label className="required">Código do medidor
                            <input
                                type="text"
                                value={sideccr.codigo}
                                name="codigo"
                                onChange={(e) => this.handleChangeSideccr(e)}
                            />
                            <span className={!validacaoSideccr.codigo ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label className="required">Chave
                            <input
                                type="text"
                                value={sideccr.chave}
                                name="chave"
                                onChange={(e) => this.handleChangeSideccr(e)}
                            />
                            <span className={!validacaoSideccr.chave ? "hdv-required-field" : "hdv-required-field hdv-noshow-item"}>* Campo Obrigatório</span>
                        </label>
                        {this.props.match.params.id ?
                            <label className="required">Equipamento
                                <Select
                                    type="text"
                                    isDisabled={true}
                                    value={sideccr.dispositivo}
                                />
                            </label>
                            : null}
                    </div>
                    <div className="col-md-6">
                        <label className="required" >
                            <input
                                type="checkbox"
                                className="checkbox"
                                name="chave"
                                checked={sideccr.enviarDados}
                                onChange={(e) => this.handleCheckbox(e)}
                            />&nbsp;
                            Enviar dados para SiDeCC-R
                        </label>
                    </div>
                </form>
                <br />

                <div className="hdv-btn-group" style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className="badge-success"
                            style={{ fontSize: '14px', padding: '0px 10px', borderRadius: '8px', margin: '5px 0' }}>
                            {this.state.salvoMsg}
                        </p>
                        <p className="badge-danger"
                            style={{ fontSize: '14px', padding: '0px 10px', borderRadius: '8px', margin: '5px 0' }}>
                            {this.state.erroMsg}
                        </p>
                    </div>
                </div>

                <div className="hdv-btn-group">
                    <button onClick={() => this.saveAction(false)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar</button>
                    <button onClick={() => this.saveAction(true)} className="hdv-btn-forms hdv-btn-geen" disabled={this.state.cooldown}>Salvar e Cadastrar um Novo</button>
                    <Link to="/sideccr">
                        <button className="hdv-btn-forms hdv-btn-orange">Cancelar</button>
                    </Link>
                </div>

            </div >
        )
    }
}