import React, { Component } from 'react';
import ReactTable from "react-table";
import { Link, Redirect } from "react-router-dom"
import _ from "underscore"
import axios from 'axios'
import auth from '../../utils/auth';
import cfg from '../../utils/config';
import 'react-table/react-table.css'

class Grid extends Component {

    state = {
        selected: [],
        selectAll: 0,
        data: [],
        pages: null,
        loading: true,
        filtered: true,
        enableDeletar: false,
        enableEditar: false,
        redirectToEdit: false
    };

    toggleRow = (id) => {
        let selecionados = this.state.selected
        let editar = false
        let deletar = false

        if (selecionados.includes(id)) {
            selecionados = selecionados.filter(item => item !== id)
        } else {
            selecionados.push(id)
        }

        if (selecionados.length === 1) {
            editar = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        this.setState({
            enableEditar: editar,
            enableDeletar: deletar,
            selected: selecionados,
            selectAll: 2
        });
    }

    toggleSelectAll = () => {
        let selecionados = [];
        let editar = false
        let deletar = false

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                selecionados.push(x.id);
            });
        }

        if (selecionados.length === 1) {
            editar = true
        }

        if (selecionados.length >= 1) {
            deletar = true
        }

        this.setState({
            enableEditar: editar,
            enableDeletar: deletar,
            selected: selecionados,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    getClientes = _.debounce((state, instance) => {
        if (state) {
            if ("pageSize" in state) {
                this.setState({ loading: true });
                let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };
                let userInfo = auth.getUserInfo();

                axios.get(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/setor/?page=' + state.page + '&pageSize=' + state.pageSize + '&filter=' + encodeURI(JSON.stringify(state.filtered)), config_me)
                    .then((response) => {
                        this.setState({ selected: [], data: response.data.results, loading: false, pages: response.data.pages, filtered: true });
                    })
            }
        }
    }, 500)

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo();
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } };

        this.setState({ loading: true, enableDeletar: false, enableEditar: false });

        this.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_version + '/' + userInfo.empresa + '/setor/' + value + '/', config_me))
        );

        axios.all(requestArray).then(axios.spread((acct, perms) => this.refReactTable.fireFetchData()))
    }

    editar = () => {
        let toGo = "setor/" + this.state.selected[0] + "/editar"
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from });
    }

    render() {
        const { redirectToEdit } = this.state;
        if (redirectToEdit) {
            return <Redirect to={redirectToEdit} />;
        }

        const columns = [{
            Header: x => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.selectAll === 1}
                        ref={input => {
                            if (input) {
                                input.indeterminate = this.state.selectAll === 2;
                            }
                        }}
                        onChange={() => this.toggleSelectAll()}
                    />
                );
            },
            sortable: false,
            width: 55,
            accessor: "",
            filterable: false,
            Cell: ({ original }) => {
                return (
                    <div className="hdv-aligncenter-checkboxgrid">
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={(this.state.selected.includes(original.id) === true ? true : false)}
                            onChange={() => this.toggleRow(original.id)}
                        />
                    </div>
                );
            }
        }, {
            Header: 'Setor',
            accessor: 'nome'
        }, {
            Header: 'Nome da Aplicação',
            accessor: 'aplicacao__nome'
        }]
        return (
            <div>

                <h4>Cadastro de Setores</h4>
                <div className="hdv-btn-group">
                    <Link to="/setor/novo">
                        <button className="hdv-btn-forms hdv-btn-geen ">Novo</button>
                    </Link>
                    <button onClick={() => { if (this.state.enableEditar === true) { this.editar() } }} className={"hdv-btn-forms hdv-btn-yellow " + (this.state.enableEditar === true ? "" : "hdv-btn-disable")}>Editar</button>
                    <button onClick={() => { if (this.state.enableDeletar === true) { this.deletar() } }} className={"hdv-btn-forms hdv-btn-red " + (this.state.enableDeletar === true ? "" : "hdv-btn-disable")}>Excluir</button>
                </div>

                <ReactTable
                    ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                    manual
                    filterable
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    onFetchData={this.getClientes}
                    columns={columns}
                    defaultPageSize={10}
                    previousText={'Anterior'}
                    nextText={'Próximo'}
                    loadingText={'Carregando...'}
                    noDataText={'Nenhum registro para exibir'}
                    pageText={'Página'}
                    ofText={'de'}
                    rowsText={'linhas'}
                />

            </div>
        );
    }
}

export default Grid;
